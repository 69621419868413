/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const elLang = {
  student: 'μαθητης σχολειου',
  id_check_alert: 'Πελάτης: {{customer}}. Ελέγξτε τα ακόλουθα αναγνωριστικά πελατών: {{id}}.',
  id_check: 'Έλεγχος ταυτότητας',
  cart: 'Καλάθι',
  item: 'Αντικείμενο',
  items: 'Αντικείμενα',
  subtotal: 'Mερικό σύνολο',
  discount: 'Εκπτωση',
  total: 'Σύνολο',
  pay: 'Πληρωμή',
  card: 'Κάρτα',
  cash: 'Μετρητά',
  invoice: 'Τιμολόγιο',
  giftcard: 'Δωροκάρτα',
  countr_giftcard: 'Countr Δωροκάρτα',
  seqr: 'SEQR',
  bitpay: 'Bitpay',
  payment_method: 'Μέθοδος πληρωμής',
  cash_received: 'Ποσό που έχει εισπραχθεί',
  or_enter_amount: 'Ή εισάγετε το ποσό',
  payment_succeeded: 'Η πληρωμή ολοκληρώθηκε με επιτυχία',
  change_due: 'Ρέστα',
  total_paid: 'Σύνολο πληρωμής',
  send_receipt: 'Αποστολή απόδειξης',
  send_invoice: 'Αποστολή τιμολογίου',
  send: 'Αποστολή',
  delete: 'Διαγραφή',
  new_sale: 'Νέα πώληση',
  custom_price: 'Προσαρμοσμένη τιμή',
  item_description: '(Προαιρετικό) περιγραφή / όνομα προϊόντος',
  products: 'Προϊoντα',
  transactions: 'Συναλλαγες',
  reports: 'Αναφορες',
  report: 'Aναφορα',
  settings: 'Ρυθμισεις',
  sign_out: 'Αποσυνδεση',
  receipt: 'Aπoδειξη',
  refund: 'Επιστροφή χρημάτων',
  home: 'Αρχική',
  clear: 'Επαναφορά',
  add: 'Προσθήκη',
  sign_up: 'Εγγραφείτε',
  sign_up_email: 'Εγγραφείτε με email',
  sign_in: 'Συνδεθείτε',
  company_name: 'Επωνυμία Επιχείρησης',
  merchant_name: 'Yπεύθυνος επικοινωνίας',
  email_address: 'Διεύθυνση e-mail',
  phone_number: 'Τηλεφωνικό νούμερο',
  password: 'Κωδικός πρόσβασης',
  confirm_password: 'Επιβεβαίωση κωδικού',
  forgot_password_question: 'Ξεχάσατε τον κωδικό;',
  next: 'Επόμενο',
  demo: 'Eπίδειξης',
  skip: 'Παράλειψη',
  skip_registration: 'Παράλειψη εγγραφής',
  take_a_tour: 'Περιήγηση',
  items_returned: 'Αντικείμενα που επιστράφηκαν',
  wrong_billing: 'Λάθος χρέωση',
  order_cancelled: 'Η παραγγελία ακυρώθηκε',
  other: 'Αλλo',
  enter_valid_amount: 'Εισαγάγετε ένα έγκυρο ποσό',
  invalid_merchant_name: 'Μη έγκυρο άτομο επικοινωνίας',
  invalid_business_name: 'Μη έγκυρο όνομα επιχείρησης',
  invalid_email_address: 'Μη έγκυρη διεύθυνση e-mail',
  invalid_phone_number: 'Μη έγκυρος αριθμός τηλεφώνου',
  invalid_password: 'Μη έγκυρος κωδικός πρόσβασης - πρέπει να έχει μήκος τουλάχιστον 8 χαρακτήρες',
  password_mismatch: 'Αναντιστοιχία κωδικού πρόσβασης',
  coming_soon: 'Έρχεται σύντομα...',
  to_use_signup: 'Για να χρησιμοποιήσετε αυτήν τη λειτουργία, εγγραφείτε',
  login_error: 'Σφάλμα σύνδεσης - ελέγξτε τα αντικείμενα σύνδεσης και τη σύνδεση στο Διαδίκτυο',
  signup_error: 'Σφάλμα εγγραφής',
  transaction_history_empty: 'Το ιστορικό συναλλαγών είναι κενό',
  categories: 'Κατηγορίες',
  all_products: 'Ολα τα προϊoντα',
  search_product: 'Αναζήτηση προϊόντος',
  reissue_receipt: 'Επανέκδοση απόδειξης',
  print_receipt: 'Εκτύπωση απόδειξης',
  or_send_receipt: 'Ή στείλτε την απόδειξη',
  save: 'Αποθήκευση',
  saved: 'Αποθηκευμένο',
  search: 'Αναζήτηση',
  custom_item: 'Προσαρμοσμένο προϊόν',
  submit: 'Υποβολή',
  submitted: 'Υποβλήθηκε',
  note: 'Σημείωση',
  message: 'Μήνυμα',
  amount: 'Ποσό',
  number: 'Αριθμός',
  taxes: 'Φόροι',
  tax: 'Φόρος',
  enter_valid_number: 'Παρακαλώ εισάγετε έναν έγκυρο αριθμό',
  enter_valid_text: 'Εισαγάγετε έγκυρο κείμενο',
  enter_valid_phone: 'Παρακαλώ εισάγετε έναν έγκυρο αριθμό τηλεφώνου',
  enter_valid_email: 'Παρακαλώ εισάγετε μια έγκυρη διεύθυνση e-mail',
  feature_not_in_demo: 'Αυτή η λειτουργικότητα δεν είναι διαθέσιμη σε λειτουργία επίδειξης',
  provide_refund_reason: 'Πρέπει να επιλέξετε έναν λόγο επιστροφής χρημάτων',
  provide_valid_refund_amount: 'Πρέπει να δώσετε ένα έγκυρο ποσό επιστροφής χρημάτων',
  refund_exceed_refundable: 'Το ποσό επιστροφής χρημάτων υπερβαίνει το μέγιστο ποσό επιστροφής χρημάτων για αυτήν την απόδειξη',
  refund_saved: 'Η επιστροφή χρημάτων αποθηκεύτηκε',
  or_customize_discount: 'Ή προσαρμόστε την έκπτωση',
  enter_percentage: 'Εισαγάγετε το ποσοστό',
  manual_card_payment: 'Μη αυτόματη πληρωμή με κάρτα',
  did_card_payment_succeed: 'Επιτυχής πληρωμή με κάρτα;',
  manual_card_refund: 'Μη αυτόματη επιστροφή χρημάτων με κάρτα',
  did_card_refund_succeed: 'Επιτυχής επιστροφή χρημάτων κάρτας;',
  giftcard_refund: 'Επιστροφή χρημάτων σε δωροκάρτα',
  did_giftcard_refund_succeed: 'Εκδόθηκε νέα δωροκάρτα στον πελάτη;',
  yes: 'Ναί',
  no: 'Οχι',
  general: 'Γενικά',
  printer: 'Εκτυπωτής',
  barcode_scanner: 'Barcodescanner',
  payment: 'Πληρωμή',
  profile: 'Προφίλ',
  currency: 'Νόμισμα',
  printing_receipt: 'Εκτύπωση απόδειξης...',
  enter_amount: 'Εισαγετε το ποσο',
  expected_change: 'Αναμενομενα ρεστα',
  still_remaining: 'Απομένει ακόμη',
  issue_invoice: 'Έκδοση τιμολογίου',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'Διεύθυνση',
  address_continued: 'Διεύθυνση [Προαιρετικό]',
  city: 'Πόλη',
  zip: 'Ταχυδρομικός κώδικας',
  country: 'Χώρα',
  country_iso: 'Χώρα σε μορφή ISO 3166-2 (π.χ. "nl", "de", "gb")',
  invalid_address: 'Μη έγκυρη ή κενή διεύθυνση',
  invalid_city: 'Μη έγκυρη ή κενή πόλη',
  invalid_country: 'Μη έγκυρη ή κενή χώρα',
  invalid_ecommerce: 'Δεν έχει επιλεγεί πλατφόρμα ηλεκτρονικού εμπορίου',
  signup_error_contact_support: 'Σφάλμα εγγραφής, επικοινωνήστε με το support@countrhq.com',
  open_orders: 'Ανοιχτές παραγγελίες',
  order: 'Παραγγελία',
  enter_weight_g: 'Εισαγάγετε βάρος (g)',
  search_scanner: 'Αναζήτηση για σαρωτή Socket CHS',
  order_settings: 'Ρυθμίσεις καλαθιού',
  order_amount: 'Αριθμός διαθέσιμων καλαθιών',
  receipts: 'Αποδείξεις',
  print_receipts: 'Εκτυπώστε αυτόματα αποδείξεις',
  open_cash_drawer: 'Αυτόματο άνοιγμα συρταριού μετρητών',
  backup: 'Αντίγραφο ασφαλείας',
  email_backup: 'Email ασφαλείας',
  server_sync: 'Συγχρονισμoς server',
  external_payment: 'Αλλo',
  search_printer: 'Αναζήτηση εκτυπωτή',
  sms_successfully_sent: 'Αποστολή SMS με επιτυχία',
  error_sending_sms: 'Σφάλμα κατά την αποστολή SMS, δοκιμάστε ξανά αργότερα',
  email_successfully_sent: 'Το email εστάλη επιτυχώς',
  error_sending_email: 'Σφάλμα κατά την αποστολή email, δοκιμάστε ξανά αργότερα',
  transaction: 'Συναλλαγή',
  logging_in: 'Σύνδεση...',
  downloading_products: 'Λήψη προϊόντων...',
  add_to_cart: 'Προσθήκη στο καλάθι',
  invalid_price: 'Μη έγκυρη τιμή',
  invalid_discount: 'Μη έγκυρη έκπτωση',
  invalid_new_price: 'Μη έγκυρη νέα τιμή - πρέπει να είναι μικρότερη από την αρχική τιμή',
  invalid_amount: 'Μη έγκυρο ποσό',
  edit_price: 'Επεξεργασία τιμής',
  discount_perc: 'Εκπτωση (%)',
  quantity: 'Ποσότητα',
  remove: 'Αφαίρεση',
  sales_info: 'Πληροφοριες πωλησεων',
  summary: 'Περίληψη',
  refunded_information: 'Πληροφορίες επιστροφής προιόντος',
  total_sale: 'Συνολική πώληση',
  total_amount_refunded: 'Επιστράφηκε συνολικά',
  remaining: 'Υπόλοιπο',
  sold_by: 'Πουλήθηκε από',
  source_receipt_number: 'Αριθμός απόδειξης πηγής',
  issued_date: 'Ημέρα έκδοσης',
  issued_time: 'Ώρα έκδοσης',
  refunded_by: 'Επιστροφή χρημάτων από',
  reason: 'Λόγος',
  receipt_number: 'Αριθμός απόδειξης',
  refunded_date: 'Ημερομηνία επιστροφής χρημάτων',
  refunded_time: 'Ώρα επιστροφής χρημάτων',
  first_name: 'Ονομα',
  last_name: 'Επίθετο',
  shop_category: 'Κατηγορία καταστήματος',
  choose_country: 'Διάλεξε μία χώρα...',
  you_will_receive_email: 'Συγχαρητήρια, η εγγραφή σας ολοκληρώθηκε.',
  wait_email: 'Τώρα θα λάβετε ένα email με περαιτέρω οδηγίες σχετικά με τον τρόπο σύνδεσης της πλατφόρμας ηλεκτρονικού εμπορίου με το Countr POS.',
  open_dashboard: 'Τώρα μπορείτε να διαχειριστείτε τον λογαριασμό σας και να δημιουργήσετε προϊόντα στον προσωπικό σας πίνακα ελέγχου (dashboard) της Countr.',
  by_signing_up: 'Με την εγγραφή αποδέχομαι τους',
  terms_and_conditions: 'Ορους και Προϋποθέσεις',
  and: 'και',
  privacy_policy: 'Πολιτική απορρήτου',
  refund_items: 'Επιστροφή χρημάτων',
  forgot_password: 'Ξεχάσατε τον κωδικό;',
  register_device: 'Εγγραφή συσκευής',
  device_not_registered: 'Αυτή η συσκευή δεν είναι καταχωρημένη',
  want_to_add_to_account: 'Καταχωρίστε τη τώρα;',
  if_ios_previously_registered: 'Εάν έχετε επανεγκαταστήσει πρόσφατα το Countr, πρέπει να καταχωρήσετε ξανά τη συσκευή σας.',
  new_device_info: 'Θα σας ζητηθεί στην επόμενη οθόνη να συνδέσετε τη συσκευή σας σε ένα από τα καταστήματά σας',
  not_now: 'Οχι τώρα',
  proceed: 'Συνέχεια',
  more_info_click: 'Περισσότερες πληροφορίες? Κάντε κλικ εδώ',
  your_detail: 'Οι πληροφορίες σου',
  vat_no: 'Αριθμός AΦM',
  billing_details: 'Πληροφορίες χρέωσης',
  add_store: 'Προσθήκη καταστήματος',
  or_add_to_existing_store: 'Ή προσθέστε στο υπάρχον κατάστημα',
  add_new_store: 'Προσθήκη νέου καταστήματος',
  store_information: 'Πληροφορίες καταστήματος',
  store_name: 'Όνομα καταστήματος',
  go_to_web_dashboard: 'Μεταβείτε στον πίνακα ελέγχου (dashboard)',
  error_create_account: 'Σφάλμα κατά τη δημιουργία λογαριασμού',
  error_creation_error: 'Παρουσιάστηκε σφάλμα κατά την εγγραφή.',
  error_wrong_phone_number: 'Παρακαλώ εισάγετε έναν έγκυρο αριθμό τηλεφώνου.',
  error_try_again: 'Πρόβλημα σύνδεσης. Παρακαλώ προσπαθησε ξανα',
  error_from_the_server: 'Δεν είναι δυνατή η σύνδεση με το server',
  error_update_cc_info: 'Δεν είναι δυνατή η ενημέρωση των στοιχείων της πιστωτικής κάρτας',
  error_add_device: 'Δεν είναι δυνατή η προσθήκη συσκευής στο τρέχον κατάστημα',
  error_add_store: 'Δεν είναι δυνατή η προσθήκη νέου καταστήματος',
  are_not_valid: 'δεν είναι έγκυρα',
  is_not_valid: 'δεν είναι έγκυρο',
  are_empty: 'είναι άδεια',
  is_empty: 'είναι άδειο',
  are_not_selected: 'δεν έχουν επιλεγεί',
  is_not_selected: 'δεν έχει επιλεγεί',
  bill: 'Λογαριασμός',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailτο:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'Κλείσιμο',
  no_product_found: 'Δεν βρέθηκαν προϊόντα',
  please_go_to_dashboard: 'Για να προσθέσετε προϊόντα, επισκεφτείτε τη διεύθυνση https://backoffice.countrhq.com',
  select_interface: 'Επιλέξτε διεπαφή',
  refund_all: 'Επιστροφή όλων',
  purchased_items: 'Αγορασμένα αντικείμενα',
  refundable: 'Επιστροφή χρημάτων',
  to_be_refunded: 'Για επιστροφή χρημάτων',
  out_of: 'Aπό',
  enter_a_printer_name: 'Εισαγάγετε όνομα εκτυπωτή',
  cart_is_empty: 'Το καλάθι είναι άδειο',
  searching_printers: 'Αναζήτηση εκτυπωτών...',
  no_printers_found: 'Δεν βρέθηκαν εκτυπωτές. Εάν αναζητάτε έναν εκτυπωτή Bluetooth, βεβαιωθείτε ότι το Bluetooth σας είναι ενεργοποιημένο στις ρυθμίσεις της συσκευής',
  total_due: 'Σύνολο οφειλόμενου ποσού',
  payment_not_successful: 'Η πληρωμή δεν ήταν επιτυχής.',
  error_processing_payment: 'Σφάλμα κατά την επεξεργασία της πληρωμής.',
  purchase: 'Αγορά',
  transaction_aborted: 'Η συναλλαγή ματαιώθηκε',
  authorization_error: 'Σφάλμα εξουσιοδότησης',
  authorization_ok: 'Εξουσιοδότηση ΟΚ',
  transaction_cancelled: 'Η συναλλαγή ακυρώθηκε',
  clearance_error: 'Σφάλμα εκκαθάρισης από τον επεξεργαστή',
  clearance_ok: 'Η εκκαθάριση επιβεβαιώθηκε από τον επεξεργαστή',
  transaction_declined: 'Η συναλλαγή απορρίφθηκε',
  transaction_complete: 'Η συναλλαγή ολοκληρώθηκε',
  initiating: 'Εκκίνηση',
  application_selected: 'Επιλέχθηκε εφαρμογή',
  scheduled_for_clearance: 'Προγραμματισμένο για εκκαθάριση εκτός σύνδεσης από τον επεξεργαστή',
  terminal_error: 'Σφάλμα τερματικού',
  unknown_status: 'Άγνωστη κατάσταση',
  waiting_for_clearance: 'Αναμονή επιβεβαίωσης εκκαθάρισης εκτός σύνδεσης από τον επεξεργαστή',
  amount_exceeded: 'Υπέρβαση μέγιστου ποσού',
  authorization_timeout: 'Χρονικό όριο εξουσιοδότησης',
  batch_processing_failed: 'Η μαζική επεξεργασία απέτυχε',
  cancelled: 'Ακυρώθηκε',
  card_blocked: 'Η κάρτα έχει αποκλειστεί',
  card_invalid: 'Η κάρτα δεν είναι έγκυρη',
  card_declined: 'Η κάρτα απορρίφθηκε',
  transaction_failed: 'H συναλλαγή απέτυχε',
  insufficient_funds: 'Ανεπαρκες υπόλοιπο',
  terminal_not_paired: 'Το τερματικό δεν έχει αντιστοιχιστεί - δοκιμάστε να επανεκκινήσετε το τερματικό',
  pin_incorrect_last_retry: 'Λανθασμένο PIN - τελική προσπάθεια',
  pin_incorrect_retry: 'Λανθασμένο PIN - δοκιμάστε ξανά',
  printer_busy: 'Ο εκτυπωτής είναι απασχολημένος',
  printer_error: 'Σφάλμα εκτυπωτή',
  printer_not_present: 'Ο εκτυπωτής δεν υπάρχει',
  printer_out_of_paper: 'Ο εκτυπωτής δεν έχει χαρτί',
  success: 'Επιτυχία',
  system_error: 'Σφάλμα συστήματος',
  transaction_not_found: 'Η συναλλαγή δεν βρέθηκε',
  unknown_device: 'Αγνωστη συσκευή',
  apply_discount: 'Εφαρμόστε έκπτωση',
  seqr_id: 'SEQR αναγνωριστικό εμπόρου (Merchant ID)',
  seqr_password: 'SEQR κωδικός πρόσβασης εμπόρου (Merchant Password)',
  bitpay_api_token: 'Bitpay API Token',
  registration_successful: 'Επιτυχής καταχώριση τερματικού',
  unregistration_successful: 'Επιτυχής κατάργηση τερματικού',
  payment_init_not_successful: 'Η πληρωμή δεν έχει αρχικοποιηθεί - ελέγξτε τη σύνδεση δικτύου.',
  always_show_addons: 'Να εμφανίζονται πάντα πρόσθετα',
  show_product_image: 'Εμφάνιση εικόνας προϊόντος',
  settings_connected_printers: 'Συνδεδεμένοι εκτυπωτές',
  settings_add_new_printer: 'Προσθήκη νέου εκτυπωτή',
  wifi_lan: 'WiFi ή LAN',
  bluetooth: 'Bluetooth',
  usb: 'USB',
  paired_barcode_scanners: 'Αντιστοιχισμενοι barcodescanners',
  account: 'Λογαριασμός',
  refresh_products: 'Ανανέωση προϊoντων',
  server_synchronisation: 'Συγχρονισμός server',
  sales: 'Πωλήσεις',
  today: 'Σήμερα',
  refunds: 'Επιστροφές',
  product: 'Προϊόν',
  product_by_category: 'Προϊόντα ανά κατηγορία',
  see_categories_details: 'Δείτε λεπτομέρειες κατηγορίας',
  open_orders_finish_payment_or_empty: 'Υπάρχουν ανοιχτά καλάθια. Ολοκληρώστε την πληρωμή ή αδειάστε το καλάθι.',
  name: 'Ονομα',
  loading_products: 'Φόρτωση προϊόντων',
  you_should_select_at_least_one_item: 'Πρέπει να επιλέξετε τουλάχιστον ένα στοιχείο',
  select_card_provider: 'Επιλέξτε έναν πάροχο καρτών',
  user_cancelled: 'Η πληρωμή ακυρώθηκε από τον πελάτη',
  card_payment: 'Πληρωμή με κάρτα',
  manage_products: 'Διαχείριση προϊόντων',
  edit_mode: 'Back-office',
  lock: 'Κλειδωμα',
  loyalty: 'Αφοσίωση',
  account_card: 'Κάρτα λογαριασμού',
  account_lookup: 'Αναζήτηση λογαριασμού',
  Activate: 'Ενεργοποίηση',
  add_anonymous_customer: 'Προσθήκη ανώνυμου πελάτη',
  add_customer: 'Προσθήκη πελάτη',
  add_customer_to_cart: 'Επιλέξτε πελάτη',
  add_new_account: 'Προσθήκη νέου λογαριασμού',
  add_new_customer: 'Προσθήκη νέου πελάτη',
  add_note_hint: 'Προσθήκη σημείωσης',
  add_tax_to_price: 'Οι τιμές δεν περιλαμβάνουν φόρο',
  add_tip: 'Προσθέστε φιλοδωρήματα',
  add_to_store: 'Προσθήκη καταστήματος',
  addons: 'Πρόσθετα',
  admin_pin: 'PIN διαχειριστή',
  admin_pin_saved: 'Αποθηκεύτηκε νέο PIN διαχειριστή',
  admin_pin_should_be: 'Το PIN διαχειριστή πρέπει να είναι ένας αριθμός μεταξύ 4 και 6 ψηφίων.',
  aid: 'AID',
  aidCard: 'aidCard',
  allow_cart_discounts: 'Επιτρέψτε εκπτώσεις σε επίπεδο καλαθιού',
  allow_custom_cart_discounts: 'Επιτρέψτε εκπτώσεις σε επίπεδο καλαθιού',
  allow_custom_items: 'Επιτρέψτε προσαρμοσμένα προϊόντα',
  allow_product_discounts: 'Επιτρέψτε εκπτώσεις σε επίπεδο προϊόντος',
  allow_custom_product_discounts: 'Επιτρέψτε προσαρμοσμένες εκπτώσεις σε προϊόντα',
  amount_hint: '999',
  app_version: 'Έκδοση',
  applicationLabel: 'applicationLabel',
  applicationName: 'applicationName',
  applicationPreferredName: 'applicationPreferredName',
  appName: 'appName',
  ask_tips: 'Ενεργοποίηση φιλοδωρήματος',
  authorizationCode: 'authorizationCode',
  available_points: 'Διαθέσιμοι πόντοι',
  available_rewards: 'Διαθέσιμες ανταμοιβές',
  back: 'Πίσω',
  barcode_scanner_disconnected: 'Ο barcode σας είναι συνδεδεμένος αλλά ενδέχεται να είναι απενεργοποιημένος. Προσπαθήστε να το ενεργοποιήσετε.',
  barcode_scanner_not_found: 'Δεν βρέθηκε barcode scanner',
  barcode_scanner_not_found_desc: 'Συνδέστε έναν νέο barcode scanner - ενεργοποιήστε το Bluetooth στις ρυθμίσεις της συσκευής σας και αναζητήστε έναν σαρωτή παρακάτω',
  bunq: 'bunq',
  bunq_admin_approval: 'Ένας διαχειριστής της bunq θα ελέγξει και θα εγκρίνει το ταμειακό σας μητρώο προτού χρησιμοποιηθεί.',
  bunq_instructions: '1) Ανοίξτε την εφαρμογή bunq και εισαγάγετε PIN (εάν απαιτείται) \n2) Επιλέξτε "Σάρωση κωδικού QR" ή "QR" από την κύρια οθόνη \n3) Σάρωση του κωδικού στην οθόνη \n4) Προσθέστε ένα ποσό εάν επιθυμείτε \n5) Επιβεβαίωση πληρωμής',
  can_only_refund_entire_amount: 'Μπορεί να επιστρέψει ολόκληρο το ποσό απόδειξης μέσω {{payment_method}}',
  cancel: 'Ακύρωση',
  cancel_payment: 'Ακύρωση πληρωμής',
  cannot_pay_later_more_than_remaining_value: 'Δεν μπορείτε να πληρώσετε περισσότερα από την υπόλοιπη αξία ({{amount}}) με μέθοδο πληρωμής αργότερα.',
  cannot_refund_transaction_with_partial_postponed_payments: 'Λυπούμαστε, αυτή η συναλλαγή περιέχει μία ή περισσότερες μερική αναβολή πληρωμών, πληρώστε πρώτα τη συναλλαγή ή επιστρέψτε την',
  cant_find_products: 'Δεν μπορείτε να βρείτε όλα τα προϊόντα σας στο POS; Χρησιμοποιήστε το παραπάνω πλαίσιο αναζήτησης ή ένα barcodescanner για να τα βρείτε.',
  card_scheme: 'Σχέδιο καρτών',
  cardBrand: 'cardBrand',
  cardExpiryMonth: 'Μήνας λήξης κάρτας MM',
  cardExpiryYear: 'Έτος λήξης κάρτας YY',
  cardType: 'cardType',
  cart_is_frozen_finish_payment: 'Το καλάθι είναι παγωμένο. Ολοκληρώστε την πληρωμή πριν κάνετε οποιαδήποτε ενέργεια.',
  cash_difference: 'Διαφορά',
  cash_drawer: 'Συρτάρι μετρητών',
  cash_in: 'Cash in',
  cash_in_out: 'Cash in/out',
  cash_out: 'Cash out',
  change: 'Αλλαγή',
  change_currency: 'Αλλαγή νομίσματος',
  change_given: 'η αλλαγή δόθηκε',
  change_open_orders_amount: 'Αλλαγή ποσού ανοιχτού καλαθιού',
  check_location_settings: 'Ελέγξτε τις ρυθμίσεις τοποθεσίας',
  check_network: 'Ελέγξτε τη σύνδεση δικτύου',
  choose_category: 'Επιλέξτε μία κατηγορία επιχείρησης...',
  choose_mobile_provider: 'Ενεργοποιήθηκαν πολλοί πάροχοι - επιλέξτε έναν',
  clear_settings: 'Διαγραφή ρυθμίσεων',
  clear_settings_successful: 'Διαγράφηκαν επιτυχώς οι ρυθμίσεις',
  clear_settings_warning: 'Η εκκαθάριση αυτών των ρυθμίσεων θα επαναφέρει τις πληροφορίες σύνδεσης. Κάντε κλικ στην επιβεβαίωση για να συνεχίσετε.',
  client_ticket: 'clientTicket',
  close_shift: 'Κλείσιμο ταμείου',
  company_address: 'Διεύθυνση επιχείρησης',
  company_registration_no: 'Αριθμός Μητρώου Εταιρίας',
  completed_payments: 'Ολοκληρωμένες πληρωμές',
  confirm: 'Επιβεβαίωση',
  confirm_cash_payment: 'Επιβεβαιώστε το ποσό πληρωμής',
  confirm_new_currency: 'Επιβεβαίωση αλλαγής νομίσματος',
  confirm_open_orders_amount_change: 'Επιβεβαίωση αλλαγής του ποσού των εκκρεμών καλαθιών;',
  confirm_payment: 'Επιβεβαίωση πληρωμής',
  confirm_with_change: 'Επιβεβαιώστε με αλλαγή',
  confirm_with_tip: 'Επιβεβαιώστε με φιλοδώρημα',
  connect_barcode: 'Συνδέστε το barcodescanner (Socket CHS)',
  connect_to_webshop: 'Συνδέστε το ηλεκτρονικό σας κατάστημα;',
  connected_printer: 'Συνδεδεμένοι εκτυπωτές',
  contact_information: 'Στοιχεία επικοινωνίας',
  contact_us: 'support@countrhq.com',
  create_product: 'Δημιουργία προϊόντος',
  currently_trial_mode_transaction_wiped_visit_dashboard: 'Το POS βρίσκεται σε κατάσταση δοκιμής. Οι συναλλαγές σας θα σβήνονται κάθε τέσσερις ώρες.',
  currently_trial_mode_visit_dashboard: 'Το POS βρίσκεται σε κατάσταση δοκιμής. Μπορείτε να μεταβείτε σε ζωντανή λειτουργία μεταβαίνοντας στον ηλεκτρονικό σας πίνακα ελέγχου.',
  customer: 'Πελάτης',
  customer_card: 'Κάρτα πελάτη',
  customer_info: 'Πληροφορίες πελάτη',
  customer_instructions: 'Οδηγίες πελάτη',
  customer_lookup: 'Αναζήτηση πελατών',
  customer_lookup_error: 'Σφάλμα server στην αναζήτηση πελατών',
  customer_name: 'Πελάτης',
  customer_not_valid: 'Ο πελάτης δεν είναι έγκυρος',
  customer_receipt: 'Απόδειξη πελάτη',
  customer_since: 'Πελάτης από',
  Date: 'Ημερομηνία',
  deregister_terminal: 'Κατάργηση εγγραφής συσκευής',
  description_mandatory: 'Μια περιγραφή είναι υποχρεωτική για συναλλαγές cash-in και cash-out',
  device_locked: 'Η συσκευή είναι κλειδωμένη',
  device_locked_enter_pin: 'Αυτή η συσκευή είναι κλειδωμένη. \n Εισαγάγετε τον κωδικό PIN διαχειριστή για να το ξεκλειδώσετε',
  did_cheque_refund_succeed: 'Η επιταγή επιστράφηκε στον πελάτη;',
  did_mobile_payment_succeed: 'Η πληρωμή μέσω κινητού πέτυχε;',
  did_mobile_refund_succeed: 'Επιτυχής επιστροφή χρημάτων μέσω κινητού;',
  direct_connection: 'Αμεσα συνδεδεμένη',
  disabled_product: 'Απενεργοποιημένο προϊόν',
  discount_currency: 'Εκπτωση ({{currency}})',
  do_you_want_remove_payment: 'Θέλετε να καταργήσετε την πληρωμή;',
  download_adyen_app: 'Λήψη της εφαρμογής Adyen',
  download_izettle_app: 'Λήψη της εφαρμογής iZettle',
  download_payleven_app: 'Λήψη της εφαρμογής Payleven',
  download_sumup_app: 'Λήψη της εφαρμογής Sumup',
  download_yodo_app: 'Λήψη της εφαρμογής Yodo Rocket ',
  downloading_categories: 'Λήψη κατηγοριών...',
  downloading_taxes: 'Λήψη φόρων...',
  downloading_transactions: 'Λήψη συναλλαγών...',
  ecommerce_none: 'Θα φτιάξω τα προϊόντα μου',
  ecrId: 'ecrId',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'Τιμολόγιο μέσω email',
  employee_name: 'Υπάλληλος',
  employee_pin: 'PIN υπαλλήλου',
  empty_cart: 'Αδειο καλάθι',
  enable_staff_pin: 'Ενεργοποίηση σύνδεσης PIN προσωπικού',
  enter_a_valid_search_value: 'Εισαγάγετε μια έγκυρη τιμή αναζήτησης',
  enter_api_key: 'Εισαγάγετε το κλειδί ενεργοποίησης',
  enter_base_url: 'Εισαγάγετε διεύθυνση URL (συμπεριλαμβανομένου του "http: //")',
  enter_merchant_auth_code_payconiq: 'Εισαγάγετε την κεφαλίδα Εξουσιοδότηση (Authorization header) Payconiq',
  enter_merchant_iban_payconiq: 'Εισαγάγετε τον IBAN που έχετε καταχωρίσει στο Payconiq',
  enter_merchant_id_payconiq: 'Εισαγάγετε το αναγνωριστικό εμπόρου (Merchant ID) Payconiq',
  enter_order_name: ' Εισαγάγετε όνομα καλαθιού',
  enter_reseller_id: 'Εισαγάγετε το αναγνωριστικό μεταπωλητή (Reseller ID)',
  enter_user_number: 'Εισαγάγετε τον αριθμό χρήστη σας',
  enter_user_password: 'Εισαγάγετε τον κωδικό πρόσβασης χρήστη',
  error_adding_device: 'Παρουσιάστηκε σφάλμα κατά την εγγραφή της συσκευής σας',
  error_refreshing_merchant: 'Σφάλμα κατά την ανάκτηση πληροφοριών λογαριασμού.',
  error_retrieve_categories: 'Σφάλμα κατά την ανάκτηση κατηγοριών. \nΕλέγξτε τη σύνδεσή σας στο διαδίκτυο και δοκιμάστε ξανά',
  error_retrieve_products: '> Σφάλμα κατά την ανάκτηση προϊόντων. \nΕλέγξτε τη σύνδεσή σας στο διαδίκτυο και δοκιμάστε ξανά',
  error_retrieve_taxes: 'Σφάλμα κατά την ανάκτηση φόρων. \nΕλέγξτε τη σύνδεσή σας στο διαδίκτυο και δοκιμάστε ξανά',
  error_while_retrieving_results: 'Σφάλμα κατά την ανάκτηση αποτελεσμάτων',
  errorCode: 'errorCode',
  errorMessage: 'errorMessage',
  ersReference: 'ersReference',
  excess_amount: 'Υπέρβαση ποσού',
  expected_amount: 'Αναμενόμενο ποσό',
  expected_change_gratuity: 'Αναμενομενα ρεστα η φιλοδωρημα',
  external_url: 'Εξωτερική διεύθυνση URL',
  failed_cancel: 'Αποτυχία ακύρωσης συναλλαγής',
  feature_only_android: 'Αυτή η λειτουργία είναι διαθέσιμη μόνο για Android',
  find_terminal: 'Αναζήτηση τερματικών',
  for_security_enter_password: 'Για λόγους ασφαλείας, εισαγάγετε ξανά τον κωδικό πρόσβασής σας',
  for_this_device: 'Μόνο για αυτήν τη συσκευή',
  for_this_store: 'Μόνο για αυτό το κατάστημα',
  four_inch: '4 ίντσες',
  generate_keys: 'Δημιουργία κλειδιών',
  generating_keypair: 'Δημιουργία keypair... \nΑυτό μπορεί να διαρκέσει αρκετά λεπτά',
  generating_reports: 'Δημιουργία αναφοράς...',
  generic_usb: 'Γενικό USB',
  get_your_free_pos: 'Αποκτήστε το POS σας στο www.countrhq.com',
  gift_code: 'Κωδικός δώρου',
  gratuity_amount: 'Ποσό φιλοδωρήματος',
  gratuity_percentage: 'Ποσοστό φιλοδωρήματος',
  group_items_in_cart: 'Ομαδοποιήστε τα ίδια είδη στο καλάθι',
  shared_secret: 'SharedSecret',
  impossible_refresh_you_have_transactions: 'Δεν έχετε συγχρονίσει {{transaction_number}} συναλλαγές με τον server. \nΟ συγχρονισμός αυτών των συναλλαγών θα επιχειρείται τώρα στο παρασκήνιο ...',
  impossible_signout_you_have_transactions: 'Αδύνατο να αποσυνδεθείτε. \nΔεν έχετε {{transaction_number}} συναλλαγές που δεν συγχρονίζονται με το διακομιστή. \nΟ συγχρονισμός αυτών των συναλλαγών θα επιχειρείται τώρα στο παρασκήνιο...',
  insert_printer_name: 'Εισαγάγετε ένα όνομα για τον εκτυπωτή σας (3 χαρακτήρες ή περισσότερους).',
  inserted_login_not_valid: 'Μη έγκυρη σύνδεση',
  inserted_value_not_valid: 'Η τιμή που έχει εισαχθεί δεν είναι έγκυρη',
  installmentAmount: 'installmentAmount',
  invalid_credit_card_number: 'Ο αριθμός της πιστωτικής κάρτας πρέπει να είναι 16 ψηφία',
  invoiceReference: 'invoiceReference',
  invoiceStatus: 'invoiceStatus',
  ip_address: 'Διεύθυνση IP',
  is_business: 'Στοιχεία επιχείρησης',
  is_correct_amount_tendered: 'Είναι το {{amount}} το σωστό ποσό;',
  isDone: 'Τίποτα άλλο για φόρτωση...',
  isError: 'Πατήστε για να δοκιμάσετε ξανά...',
  isTap: 'Πατήστε για φόρτωση περισσότερων...',
  join_thousands_of_merchants: 'Χιλιάδες έμποροι έχουν ενταχθεί στη Countr. Σήμερα είναι η σειρά σας.Εγγραφείτε δωρεάν',
  language: 'Γλώσσα',
  last_visit: 'Τελευταία επίσκεψη',
  leave_tour: 'Αφήστε την περιοδεία',
  leaving_tour: 'Αποχώρηση από περιοδεία...',
  loyal_customer: 'Πιστός πελάτης',
  mac_address: 'Διεύθυνση MAC',
  mailing_list_permission: 'Προσθήκη στη λίστα αλληλογραφίας',
  manual_mobile_payment: 'Πληρωμή μέσω κινητού',
  manual_mobile_refund: 'Επιστροφή χρημάτων μέσω κινητού',
  max_amount_exceeded: 'Το ποσό υπερβαίνει το μέγιστο επιτρεπόμενο για αυτόν τον τρόπο πληρωμής',
  menu_item_12char: 'Το όνομα του στοιχείου μενού δεν μπορεί να υπερβαίνει τους 12 χαρακτήρες',
  menu_item_name: 'Όνομα στοιχείου μενού (έως 12 χαρακτήρες)',
  merchant_receipt: 'Απόδειξη εμπόρου',
  merchantDisplayName: 'merchantDisplayName',
  merchantId: 'merchantID',
  mid: 'MID',
  mobile_number: 'Αριθμός κινητού',
  mobile_payment: 'Κινητό',
  mobile_payments: 'Πληρωμές μέσω κινητού',
  new_price: 'Νέα τιμή ({{currency}})',
  no_available_addons_for_product: 'Δεν υπάρχουν διαθέσιμα πρόσθετα για το επιλεγμένο προϊόν.',
  no_connected_printer: 'Δεν βρέθηκαν εκτυπωτές',
  no_connected_terminal: 'Δεν βρέθηκαν τερματικά',
  no_customer_found: 'Δεν βρέθηκε πελάτης',
  no_customer_selected: 'Δεν έχει επιλεγεί πελάτης',
  no_printer_configured: 'Δεν έχει διαμορφωθεί εκτυπωτής',
  no_printer_found: 'Δεν βρέθηκε εκτυπωτής \nΚάντε κλικ εδώ εάν διαθέτετε εκτυπωτή Star με σύνδεση USB',
  no_product_in_cart: 'Δεν υπάρχει προϊόν στο καλάθι',
  no_terminals_found: 'Δεν βρέθηκαν τερματικά',
  no_url_specified: 'Σφάλμα, δεν έχει καθοριστεί διεύθυνση URL.',
  none: 'Κανένα',
  note_mandatory_cash_difference: 'Μια σημείωση είναι υποχρεωτική εάν υπάρχει διαφορά στα αναμενόμενα και πραγματικά ποσά',
  note_optional: 'Σημείωση (προαιρετικό)',
  numberOfInstallments: 'numberOfInstallments',
  numpad: 'Numpad',
  offline: 'Εκτός σύνδεσης',
  ok: 'Eντάξει',
  one_or_more_carts_are_frozen_finish_payment: 'Ένα ή περισσότερα καλάθια είναι παγωμένα. Ολοκληρώστε την πληρωμή πριν κάνετε οποιαδήποτε ενέργεια.',
  online: 'Σε σύνδεση',
  open_market: 'Θέλετε να ανοίξετε την αγορά;',
  open_register_before_transaction: 'Πρέπει να ανοίξετε το ταμείο για να μπορέσετε να πραγματοποιήσετε συναλλαγές',
  open_shift: 'Ανοίξτε το ταμείο',
  order_printer: 'Εκτυπωτής καλαθιού',
  orderId: 'orderID',
  orderRef: 'orderRef',
  other_text: 'Άλλο κείμενο',
  paid: 'Πληρωμένο',
  paid_so_far: 'Πληρώθηκε μέχρι τώρα',
  paid_with_method: 'Πληρώθηκε με {{method}}',
  pan: 'PAN',
  panLastDigits: 'Τελευταία ψηφία Pan',
  panMasked: 'Pan Masked',
  PANSeq: 'PANSeq',
  partial_payment: 'Μερική πληρωμή',
  partial_payment_not_allowed_for: 'Δεν επιτρέπονται μερικές πληρωμές',
  partial_payment_paid_successfully: 'Η μερική πληρωμή έχει πληρωθεί με επιτυχία',
  pay_later: 'Πλήρωσε αργότερα',
  pay_later_allows_only_refund_all: 'Με πληρωμή αργότερα, μπορείτε να επιστρέψετε όλα τα χρήματα μόνο. Για μερική επιστροφή χρημάτων, ολοκληρώστε την πληρωμή.',
  pay_later_refund: 'Ακύρωση πληρωμής αργότερα',
  pay_on_account: 'Πληρώστε στο λογαριασμό',
  payconiq: 'Payconiq',
  paying_now: 'Πληρωμή τώρα',
  payment_cancelled: 'Η πληρωμή ακυρώθηκε',
  payment_error: 'Σφάλμα πληρωμής',
  payment_info: 'Πληροφορίες πληρωμής',
  payment_insert_card: 'Εισαγωγή κάρτας',
  payment_method_not_configured: '{{payment_method}} δεν έχει ρυθμιστεί σωστά σε αυτήν τη συσκευή',
  payment_number: 'Αριθμός πληρωμής',
  payment_processor_options: 'Επιλογές επεξεργαστή πληρωμών',
  payment_provider: 'Πάροχος πληρωμών',
  payment_success: 'Επιτυχής Πληρωμή',
  payment_terms_in_net_days: 'Όροι πληρωμής (καθαρές ημέρες)',
  personal_information: 'Προσωπικές πληροφορίες',
  pickup_rewards: 'Πάρτε ανταμοιβές',
  pin: 'PIN',
  please_insert_a_valid_amount: 'Εισαγάγετε ένα έγκυρο ποσό',
  posEntryMode: 'posEntryMode',
  prefName: 'prefName',
  print: 'Εκτύπωση',
  print_also_product_details: 'Θα θέλατε επίσης να εκτυπώσετε λεπτομέρειες προϊόντος;',
  print_error: 'Σφάλμα εκτύπωσης',
  print_product_details: 'Εκτυπώστε λεπτομέρειες προϊόντος',
  print_successful: 'Η εκτύπωση ήταν επιτυχής',
  printer_connect_desc: 'Οι εκτυπωτές WiFi πρέπει να είναι συνδεδεμένοι στο ίδιο δίκτυο με αυτήν τη συσκευή. \nΟι εκτυπωτές Bluetooth πρέπει να αντιστοιχιστούν μέσω των ρυθμίσεων συστήματος πριν να είναι διαθέσιμοι εδώ',
  printer_removed: 'Ο εκτυπωτής αφαιρέθηκε',
  printer_test: 'Δοκιμή εκτυπωτή',
  printing_report: 'Δημιουργία και εκτύπωση αναφοράς...',
  proceed_with_next_payment: 'Συνεχίστε με την επόμενη πληρωμή.',
  processorName: 'processorName',
  product_is_disable_continue_anyway: 'Το προϊόν που επιλέξατε δεν είναι διαθέσιμο (πιθανώς δεν υπάρχει απόθεμα). Θέλετε να το προσθέσετε στο καλάθι ούτως ή άλλως;',
  product_sync: 'Συγχρονισμός προϊόντος',
  profile_average: 'Μέση αγορά',
  protected_sections: 'Προστατευμένα τμήματα',
  receipt_printer: 'Εκτυπωτής απόδειξης',
  redeem_reward: 'Εξαργυρώστε την επιβράβευση',
  referenceNumber: 'referenceNumber',
  refresh_customers: 'Ανανεωση λιστας πελατvν',
  refresh_merchant: 'Ανανεωση πληροφοριων λογαριασμου',
  refresh_transactions: 'Ανανεωση συναλλαγων',
  refreshing_merchant: 'Ανανέωση πληροφοριών λογαριασμού...',
  refund_number: 'Αριθμός επιστροφής χρημάτων',
  refunds_not_available_for_provider: 'Οι επιστροφές δεν είναι διαθέσιμες για τον πάροχο: {{payment_provider}}',
  register_terminal: 'Εγγραφή συσκευής',
  reissue: 'Επανέκδοση',
  remaining_payment: 'Υπόλοιπο πληρωμής',
  remove_customer_from_cart: 'Αφαίρεση από το καλάθι',
  remove_payment: 'Κατάργηση πληρωμής',
  remove_printer: 'Κατάργηση εκτυπωτή',
  removing_products: 'Κατάργηση παλαιών προϊόντων...',
  reopen_shift: 'Ανοίξτε ξανά το ταμείο',
  replace_customer: 'Αντικατάσταση πελάτη',
  report_by_shift: 'Ενεργοποίηση ανοίγματος / κλεισίματος καθημερινά',
  report_printer: 'Εκτυπωτής αναφοράς',
  requested_amount: 'Ζητούμενο ποσό',
  reward: 'Eπιβραβεύσεις',
  round_cash_transactions: 'Πληρωμές μετρητών στρογγυλής στο πλησιέστερο 0,05',
  save_printer: 'Αποθήκευση εκτυπωτή',
  scan: 'Σάρωση',
  scan_barcode_instruction_one: 'Σαρώστε τον barcode για να θέσετε το Socket Bluetooth scanner σε λειτουργία σύζευξης',
  scan_barcode_instruction_two: 'Στις ρυθμίσεις της συσκευής σας, βεβαιωθείτε ότι το Bluetooth είναι ενεργοποιημένο και, στη συνέχεια, σύζευξη του σαρωτή Socket CHS',
  scan_code_not_exist: 'Ο σαρωμένος κωδικός {{barcode}} δεν αναγνωρίζεται',
  scan_qr_code: 'Σάρωση κωδικού QR με εφαρμογή πληρωμής',
  scanner: 'Scanner',
  scanner_connected: 'Το Barcodescanner συνδέθηκε',
  scanner_disconnected: 'Το Barcodescanner αποσυνδέθηκε',
  scanner_pair_completed: 'Η σύζευξη του Barcodescanner ολοκληρώθηκε',
  search_for_customer: 'Αναζήτηση πελάτη',
  search_report: 'Αναζήτηση αναφορών',
  search_transaction: 'Αναζήτηση συναλλαγής',
  searching_terminals: 'Αναζήτηση για τερματικό(α)...',
  security_and_permissions: 'Ασφαλεια και δικαιωματα',
  select_one_payment_method: 'Πρέπει να είναι ενεργοποιημένη τουλάχιστον μία μέθοδος πληρωμής',
  select_payment_method: 'Επιλέξτε την κάρτα AID από την παρακάτω λίστα',
  select_terminal: 'Επιλέξτε ένα τερματικό',
  send_email: 'Αποστολή email',
  send_to_another_customer: 'Αποστολή σε άλλον πελάτη',
  send_to_current_customer: 'Αποστολή στον τρέχοντα πελάτη',
  send_to_customer: 'Αποστολή στον πελάτη',
  sending_receipt: 'Αποστολή απόδειξης...',
  server_error: 'Σφάλμα Server',
  session: 'Συνεδρια',
  set_new_price: 'Νέα τιμή',
  setting_saved: 'Η ρύθμιση αποθηκεύτηκε',
  settings_connected_terminals: 'Συνδεδεμένο τερματικό',
  settings_printer_available_printers: 'Διαθέσιμοι εκτυπωτές',
  settlementDate: 'settlementDate',
  setup_admin_pin_in_settings: 'Ορίστε το PIN διαχειριστή στις Ρυθμίσεις, στην καρτέλα Λογαριασμός.',
  shift_closed: 'Το ταμείο έκλεισε',
  shift_opened: 'Το ταμείο έχει ανοίξει',
  shifts: 'Βάρδιες',
  shopper_reference: 'ShopperReference',
  show_customer: 'Εμφάνιση πληροφοριών πελάτη κατά την απόδειξη',
  show_employee: 'Εμφάνιση ονόματος υπαλλήλου κατά την απόδειξη',
  show_customer_payconiq: 'Επιλέξτε έναν πελάτη που έχει εισέλθει ή σαρώστε τον barcode / διακριτικό (token) NFC του πελάτη',
  show_new_product_tile: 'Εμφάνιση πλακιδίου "δημιουργία προϊόντος"',
  show_password: 'Δείξε τον κωδικό',
  sign_out_header: 'Είσαι σίγουρος;',
  sign_out_warning: 'Η αποσύνδεση ενδέχεται να επαναφέρει τις ρυθμίσεις της εφαρμογής σας, συμπεριλαμβανομένων παρόχων πληρωμών και εκτυπωτών. Χρησιμοποιήστε τη λειτουργία Κλείδωμα (με PIN διαχειριστή ή υπαλλήλου) εάν δεν θέλετε να διαγράψετε τις ρυθμίσεις σας.',
  sign_up_to_print: 'Εγγραφείτε για δωρεάν δοκιμαστικό λογαριασμό για εκτύπωση απόδειξης. Δεν απαιτείται πιστωτική κάρτα, ακυρώστε ανά πάσα στιγμή.',
  sign_up_to_send_email: 'Εγγραφείτε για δωρεάν δοκιμαστικό λογαριασμό για να στείλετε μια απόδειξη μέσω email. Δεν απαιτείται πιστωτική κάρτα, ακυρώστε ανά πάσα στιγμή.',
  signing_out: 'Αποσύνδεση…',
  signup_for_mailing_list: 'Ζητήστε από τους πελάτες να εγγραφούν στη λίστα αλληλογραφίας',
  sorry_report_not_available: 'Η αναφορά δεν είναι διαθέσιμη.',
  start_using_pos: 'Ξεκινήστε τις πωλήσεις με την Countr',
  state: 'Νομός',
  status: 'Κατάσταση',
  store_powered_by: 'Αυτό το κατάστημα έχει τη βοήθεια του',
  street: 'Δρόμος',
  suggested_amount: 'Προτεινoμενο ποσo',
  suggested_tip: 'Προτεινoμενo φιλοδωρημα',
  support: 'Υποστηριξη',
  telephone: 'Τηλέφωνο',
  terminal_port: 'Η θύρα έχει διαμορφωθεί για τερματικό',
  terminal_selected: 'Το τερματικό αποθηκεύτηκε',
  terminalId: 'terminalId',
  test: 'Αυτό είναι ένα τεστ',
  three_inch: '3 ίντσες',
  tid: 'TID',
  Time: 'Xρόνος',
  tip: 'Φιλοδώρημα',
  tip_added: 'Το φιλοδώρημα προστέθηκε',
  to_pay: 'Προς πληρωμή',
  total_amount: 'Συνολικό ποσό',
  transaction_code: 'transactionCode',
  transaction_date: 'TransactionDate',
  transaction_finalized: 'Η συναλλαγή ολοκληρώθηκε',
  transaction_pending: 'Εκκρεμεί η συναλλαγή',
  transaction_started: 'Η συναλλαγή ξεκίνησε',
  transaction_succeeded: 'Η συναλλαγή ολοκληρώθηκε με επιτυχία',
  transaction_time: 'Χρόνος συναλλαγής',
  transaction_timeout: 'Χρονικό όριο συναλλαγής',
  transactionSeq: 'transactionSeq',
  transactionType: 'transactionType',
  try_it_now: 'Δοκίμασέ το τώρα',
  tsi: 'TSI',
  tvr: 'TVR',
  two_inch: '2 ίντσες',
  uncategorized: 'Χωρίς κατηγορία',
  valid_phone_number: 'Έγκυρος αριθμός τηλεφώνου',
  validating_fields: 'Επικύρωση πεδίων...',
  validation_in_progress: 'Η επικύρωση βρίσκεται σε εξέλιξη',
  verification_code: 'Κωδικός επαλήθευσης',
  verification_method: 'Μέθοδος επαλήθευσης',
  waiting_for_customer: 'Αναμονή πελάτη',
  want_to_sync_products: 'Θέλετε να συγχρονίσετε τα προϊόντα σας;',
  yodopay: 'YodoPaY',
  you_must_select_a_store: 'Πρέπει να επιλέξετε ένα κατάστημα.',
  add_name_device: 'Προσθέστε ένα όνομα στο tablet / τηλέφωνο για να το αναγνωρίσετε εύκολα αργότερα (υποχρεωτικό)',
  card_number: 'CardNumber',
  change_language: 'Αλλαξε γλώσσα',
  choose_currency: 'Επιλέξτε το κύριο νόμισμα',
  clear_cart_after_finish: 'Επαναφορά ονόματος καλαθιού / τραπεζιού μετά την πληρωμή',
  confirm_new_language: 'Επιβεβαίωση νέας γλώσσας; \nΘα πρέπει να κλείσετε εντελώς και να επανεκκινήσετε την εφαρμογή για να εφαρμόσετε αυτήν την αλλαγή',
  customer_signature: 'Υπογραφή πελάτη',
  day: 'Ημερα',
  device_name_mandatory: 'Το όνομα της συσκευής είναι υποχρεωτικό',
  downloading_refunds: 'Λήψη επιστροφών...',
  email_report: 'Αποστολή αναφοράς με email',
  email_successfully_sent_test: 'Το δοκιμαστικό email εστάλη στον λογαριασμό σας',
  enter_table_name: 'Εισαγάγετε το όνομα του τραπεζιού',
  error: 'Σφάλμα',
  error_retrieve_refunds: 'Σφάλμα κατά την ανάκτηση επιστροφών',
  error_retrieve_transactions: 'Σφάλμα κατά την ανάκτηση συναλλαγών',
  for_this_device_see_dashboard: 'Μόνο για αυτήν τη συσκευή - δείτε τον Πίνακα ελέγχου (Dashboard) για περισσότερες αναφορές',
  hide_new_sale: 'Απόκρυψη της οθόνης \'Νέα πώληση\' μετά την πληρωμή',
  last_transaction_result: 'Τελευταίο αποτέλεσμα συναλλαγής',
  merge_warning: 'Αυτό θα συγχωνεύσει το περιεχόμενο αυτών των καλαθιών / τραπεζιών',
  month: 'Μηνας',
  post_code: 'PostCode',
  print_order: 'Εμφάνιση εκτυπωτή αποστολής σε παραγγελία',
  products_added_removed_refresh: 'Προϊόντα προστέθηκαν ή καταργήθηκαν - ανανέωση λίστας;',
  quarter: 'Τρίμηνο',
  refresh: 'Ανανέωση',
  refreshing_products: 'Ανανέωση προϊόντων...',
  removing_categories: 'Κατάργηση παλαιών κατηγοριών...',
  removing_taxes: 'Κατάργηση παλαιών φόρων...',
  select_interface_mandatory: 'Η επιλογή μιας διεπαφής είναι υποχρεωτική',
  sms_successfully_sent_test: 'Το δοκιμαστικό SMS εστάλη στον λογαριασμό σας',
  splitOrderPrintByCategory: 'Διαχωρισμός εκτύπωσης παραγγελιών ανά κατηγορία',
  stored_digitally: 'Αποθηκεύεται ψηφιακά',
  stripe_id: 'stripeID',
  sync_support_info: 'Συγχρονισμoς πληροφοριων υποστηριξης',
  syncing_support_info: 'Συγχρονισμός πληροφοριών υποστήριξης...',
  table: 'Τραπέζι',
  table_is_empty: 'Το τραπέζι είναι άδειο',
  tables_or_carts: 'Ενεργοποίηση λειτουργιών φιλοξενίας (hospitality)',
  uncertain_card_payment_success: 'Αβέβαιη επιτυχία πληρωμής με κάρτα',
  unknown_category: 'Άγνωστη κατηγορία',
  unsynced_transactions_report_incorrect: '{{transaction_number}} συναλλαγές που δεν συγχρονίστηκαν - οι αναφορές δεν είναι ενημερωμένες',
  update_terminal_firmware: 'Ενημέρωση υλικολογισμικού τερματικού',
  week: 'Εβδομαδα',
  year: 'Ετος',
  email_queued: 'Email σε αναμονή',
  order_received: 'Λήφθηκε νέα / ενημερωμένη παραγγελία',
  go_to_orders: 'Προβολή παραγγελιών',
  open_carts: 'Ανοιχτά καλάθια',
  auto_print_order: 'Εκτυπώστε μη εκτυπωμένες παραγγελίες επί πληρωμή',
  auto_print_order_cart: 'Εκτύπωση μη εκτυπωμένων παραγγελιών σε αλλαγή καλαθιού / τραπεζιού',
  external: 'Αλλo',
  no_printer_found_tcp: 'Δεν βρέθηκε εκτυπωτής \nΚάντε κλικ εδώ για μη αυτόματη εισαγωγή πληροφοριών εκτυπωτή',
  downloading_carts: 'Λήψη καλαθιών...',
  pay_later_hybrid: 'Πλήρωσε αργότερα',
  error_retrieve_carts: 'Σφάλμα κατά την ανάκτηση καλαθιών',
  no_ip_address: 'Χωρίς διεύθυνση',
  building_number: 'Αριθμός κτισίματος',
  confirm_manual_payment: 'Επιβεβαίωση μη αυτόματης πληρωμής',
  shipping_address: 'Διεύθυνση αποστολής',
  birth_date: 'Ημερομηνια γεννησης',
  for_this_store_see_dashboard: 'Μόνο για αυτό το κατάστημα - δείτε τον Πίνακα ελέγχου (Dashboard) για περισσότερες αναφορές',
  customers: 'Πελάτες',
  tickets_in: 'Tickets in',
  tickets_out: 'Tickets out',
  tickets_in_out: 'Tickets in/out',
  unsynced_transaction_cant_refund: 'Η συναλλαγή δεν έχει συγχρονιστεί ακόμη με το server - δεν είναι δυνατή η επιστροφή χρημάτων',
  show_stock_level: 'Εμφάνιση τρέχοντος επιπέδου αποθεμάτων (εάν υπάρχει)',
  available: 'σε απόθεμα',
  stock_not_available: 'Το απόθεμα δεν παρακολουθείται',
  order_another_store: 'Παραγγελία από άλλη τοποθεσία',
  order_products: 'Ενεργοποίηση παραγγελίας προϊόντων από άλλες τοποθεσίες',
  trial_expired: 'Η δοκιμαστική περίοδος έχει λήξει. Η λειτουργία POS έχει απενεργοποιηθεί.',
  left_menu: 'Αριστερό navbar',
  right_menu: 'Δεξιά navbar',
  direct_pay_if_one_method: 'Αυτόματη έναρξη πληρωμής εάν έχει οριστεί 1 τρόπος',
  processing_vouchers: 'Επεξεργασία αφοσίωσης (loyalty) και κουπονιών...',
  display_2_rows_of_categories: 'Εμφάνιση 2 σειρών κατηγοριών',
  unknown_barcodes_as_vouchers: 'Αντιμετωπίστε τους μη αναγνωρισμένους barcodes ως κουπόνια',
  validate_signature: 'Ισχύει η υπογραφή του πελάτη;',
  group_items_in_receipt_and_cart: 'Ομαδοποιήστε τα ίδια είδη στο καλάθι και την απόδειξη',
  ask_customer_subscribe_email: 'Ζητήστε από τον πελάτη να παράσχει διεύθυνση email',
  hide_categories: 'Απόκρυψη πλακιδίων κατηγοριών',
  hide_all_products_category: 'Απόκρυψη πλακιδίων κατηγορίας όλων των προϊόντων',
  provisional_receipt: '*** ΠΡΟΣΩΡΙΝΟΣ ***',
  reprint: '*** ΑΝΑΤΥΠΩΣΗ ***',
  block_reprints: 'Μην επιτρέπετε την επανεκτύπωση στοιχείων',
  reports_at_store_level: 'Εμφάνιση αναφορών για κατάστημα, όχι για συσκευή',
  max_addon_qty_exceeded: 'Επιτρέπονται έως {{maxQty}} πρόσθετα για αυτήν την ομάδα',
  min_addon_qty_unmet: 'Απαιτούνται τουλάχιστον {{minQty}} πρόσθετα από το {{groupName}}',
  press_button_to_wake_terminal: 'Πατήστε το πράσινο κουμπί για να ενεργοποιήσετε το τερματικό PIN',
  payments: 'Πληρωμές',
  average_sale_duration_mins: 'Μέση διάρκεια πώλησης (λεπτά)',
  save_order_move_next: 'Αποθήκευση παραγγελίας, συνέχεια στον επόμενο κενό τραπέζι',
  max_orders_reached: 'Επιτεύχθηκε ο μέγιστος αριθμός ανοιχτών παραγγελιών',
  show_open_orders: 'Εμφάνιση ανοικτών παραγγελιών πρώτα στη λίστα παραγγελιών',
  tender_created: 'Η συναλλαγή ξεκίνησε',
  invalid_request: 'Ακυρη Αίτηση',
  already_processed: 'Η συναλλαγή έχει ήδη ολοκληρωθεί',
  invalid_terminal: 'Μη έγκυρο τερματικό πληρωμής',
  network_error: 'Σφάλμα δικτύου',
  bad_credentials: 'Ακυρα διαπιστευτήρια',
  server_issue: 'Δεν είναι δυνατή η επικοινωνία με τον server',
  pin_entered: 'Το PIN εισήχθη',
  initial: 'Αρχικοποίηση',
  card_inserted: 'Η κάρτα έχει εισαχθεί',
  card_swiped: 'Σύρθηκε η κάρτα',
  wait_app_selection: 'Αναμονή επιλογής εφαρμογής',
  wait_amount_adjustent: 'Αναμονή προσαρμογής ποσού',
  ask_gratuity: 'Ζητήστε φιλοδωρήματα',
  gratuity_entered: 'Εισήλθε το φιλοδωρήματα',
  ask_dcc: 'Ζητήστε DCC',
  dcc_accepted: 'Το DCC έγινε αποδεκτό',
  dcc_rejected: 'Το DCC απορρίφθηκε',
  processing_tender: 'Επεξεργασία προσφοράς',
  wait_pin: 'Αναμονή PIN',
  provide_card_details: 'Δώστε τα αντικείμενα της κάρτας',
  card_details_provided: 'Παρέχονται αντικείμενα κάρτας',
  receipt_printed: 'Η απόδειξη εκτυπώθηκε',
  referral: 'Παραπομπή',
  referral_checked: 'Η παραπομπή ελέγχθηκε',
  check_signature: 'Ελέγξτε την υπογραφή',
  signature_checked: 'Η υπογραφή ελέγχθηκε',
  additional_data_available: 'Πρόσθετα δεδομένα διαθέσιμα',
  transaction_acknowledged: 'Η συναλλαγή αναγνωρίστηκε',
  device_already_registered: 'Η συσκευή έχει ήδη καταχωρηθεί',
  add_in_progress: 'Προστέθηκε ήδη συσκευή',
  app_not_registered: 'Η εφαρμογή δεν έχει εγγραφεί',
  unknown_error: 'Αγνωστο σφάλμα',
  timeout: 'Εληξε ο χρόνος',
  verify_failed: 'Δεν ήταν δυνατή η επαλήθευση της συσκευής',
  identify_failed: 'Could not identify device',
  registration_error: 'Σφάλμα εγγραφής',
  sync_issue: 'Δεν ήταν δυνατός ο συγχρονισμός της συσκευής',
  show_select_customer: 'Εμφάνιση επιλογέα πελατών',
  store_name_mandatory: 'Το όνομα καταστήματος είναι υποχρεωτικό',
  choose_store_dashboard_for_more: 'Επιλέξτε κατάστημα - περισσότερα μπορούν να προστεθούν μέσω του ταμπλό back-office (Dashboard)',
  recovering_incomplete_payment: 'Ανάκτηση ελλιπούς πληρωμής - αυτό μπορεί να διαρκέσει έως και 30 δευτερόλεπτα',
  allow_negative_sale: 'Επιτρέψτε αρνητικό ποσό πώλησης',
  move_current_table_to: 'Μετακίνηση τρέχοντος τραπεζιού ({{table_name}}) στο',
  store: 'Κατάστημα',
  print_specific_categories: 'Εκτύπωση συγκεκριμένων κατηγοριών',
  customer_display_attached: 'Επισυνάπτεται οθόνη πελάτη',
  sale: 'Πώληση',
  reps_and_waste_button_in_cart: 'Εμφάνιση κουμπιού χαμένων / χαλασμένων στο καλάθι',
  small_product_tiles: 'Μειώστε το μέγεθος των πλακιδίων προϊόντος',
  large_product_tiles: 'Αυξήστε το μέγεθος των πλακιδίων προϊόντος',
  show_product_price_on_tile: 'Τιμή προϊόντος σε πλακάκια',
  printer_roles: 'Ρόλοι εκτυπωτών',
  paper_size: 'Μέγεθος χαρτιού',
  customer_display: 'Οθόνη πελάτη',
  enable_realtime_updates: 'Ενεργοποίηση ενημερώσεων σε πραγματικό χρόνο από το server',
  show_open_orders_first: 'Εμφάνιση ανοικτών παραγγελιών πρώτα στη λίστα παραγγελιών',
  blank_lines_order_ticket: 'Κενές γραμμές στην κορυφή του εισιτηρίου παραγγελίας',
  blank_lines_bottom_order_ticket: 'Κενές γραμμές στο κάτω μέρος του εισιτηρίου παραγγελίας',
  scan_card: 'Σάρωση κάρτας',
  scan_nfc_or_barcode: 'Σάρωση κάρτας ή κωδικού NFC',
  show_product_keypad: 'Εμφάνιση πληκτρολογίου στο καλάθι',
  extra_payment_methods: 'Επιπλέον τρόποι πληρωμής',
  failed_recovery: 'Αποτυχία ανάκτησης πληρωμής',
  blank_line_between_order_items: 'Κενή γραμμή μεταξύ αντικειμένων εισιτηρίων παραγγελίας',
  look_up_customers_using_barcodes: 'Προσθήκη πελάτη στο καλάθι χρησιμοποιώντας barcode scanner',
  error_loading_configuration: 'Σφάλμα κατά τη φόρτωση της διαμόρφωσης',
  error_verifying_payment_terminal: 'Σφάλμα κατά την επαλήθευση του τερματικού PIN',
  error_identifying_payment_terminal: 'Σφάλμα κατά τον προσδιορισμό του τερματικού PIN',
  error_saving_configuration: 'Σφάλμα κατά την αποθήκευση της διαμόρφωσης',
  conflicting_server_modes: 'Το τερματικό Pin και ο διακομιστής έχουν συγκρουόμενες λειτουργίες (ΔΟΚΙΜΗ vs ΠΑΡΑΓΩΓΗ)',
  nothing_to_print: 'Δεν υπάρχουν αντικείμενα για εκτύπωση',
  large_item_name: 'Αύξηση μεγέθους εκτυπωμένου αντικειμένου',
  print_ean_sku: 'Εκτυπώστε EAN και SKU στην απόδειξη',
  select_category_on_startup: 'Επιλέξτε κατηγορία μία φορά κατά την εκκίνηση',
  split_tickets_item: 'Διαχωρισμός εκτύπωσης παραγγελιών ανά είδος',
  split_tickets_amount: 'Διαχωρισμός της παραγγελίας κατά ποσότητα',
  split_print: 'Διαχωρισμένη εκτύπωση',
  refreshing_customers: 'Ανανέωση πελατών...',
  downloading_customers: 'Λήψη πελατών...',
  error_retrieve_customers: 'Σφάλμα κατά την ανάκτηση πελατών. \nΕλέγξτε τη σύνδεσή σας στο διαδίκτυο και δοκιμάστε ξανά',
  server_error_please_retry: 'Σφάλμα server. Δοκιμάστε ξανά',
  vibrate_on_click: 'Ανατροφοδότηση δόνησης για κλικ στο προϊόν',
  keep_screen_on: 'Μην αφήνετε την οθόνη να κοιμάται',
  refund_same_method: 'Επιβάλλετε τις επιστροφές χρημάτων με τον αρχικό τρόπο πληρωμής',
  cannot_refund_more_than_paid: 'Δυνατότητα επιστροφής χρημάτων έως {{max_amount}} μόνο μέσω {{payment_method}}',
  tablet_date_incorrect: 'Η ημερομηνία της συσκευής δεν έχει οριστεί σωστά - πιθανός κίνδυνος ασφαλείας',
  extra_non_revenue_info: 'Ζητήστε επιπλέον πληροφορίες για αντικείμενα εκτός εσόδων',
  enter_extra_info: 'Εισαγάγετε επιπλέον πληροφορίες αντικειμένου',
  giftcard_or_reference_no: 'Δωροκάρτα ή αριθμός αναφοράς',
  inserted_code_not_valid: 'Ο κωδικός δεν είναι σωστός',
  validate_user: 'Επικύρωση χρήστη ({{user_address}})',
  enter_validation_code: 'Εισαγάγετε τον κωδικό επικύρωσης που λάβατε μέσω SMS ή email',
  tax_rate: 'Φορολογικός συντελεστής',
  pretax: 'Καθαρά',
  deposit: 'Κατάθεση',
  can_not_refund_with_different_provider: 'Δεν είναι δυνατή η επιστροφή χρημάτων για {{txn_payment_method}} συναλλαγές χρησιμοποιώντας το {{selected_payment_method}}',
  external_scanner_connected: 'Συνδεδεμένος εξωτερικό scanner',
  cancelling: 'Ακύρωση',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  rabosmartpinttp: 'Rabo SmartPIN TapToPay',
  exchange: 'Ανταλλαγή',
  ask_exchange: 'Να επιτρέπεται η ανταλλαγή προϊόντων',
  exchange_error: 'Σφάλμα ανταλλαγής',
  exchange_items: 'Ανταλλαγή αντικειμένων',
  exchangeable: 'Ανταλλάξιμος',
  to_be_exchanged: 'Για ανταλλαγή',
  exchange_all: 'Ανταλλάξτε όλα',
  negative_sale_amount: 'Το ποσό πληρωμής είναι μικρότερο από 0',
  download_rabosmartpin_app: 'Κατεβάστε την εφαρμογή Rabo SmartPin',
  transaction_disabled: 'Δεν επιτρέπονται πληρωμές από τρίτους σε αυτήν τη σύμβαση',
  transaction_in_progress: 'Μια άλλη πληρωμή βρίσκεται ήδη σε εξέλιξη',
  refund_offline: 'Δεν είναι δυνατή η επιστροφή χρημάτων σε περιορισμένη λειτουργία',
  refund_not_allowed: 'Δεν επιτρέπονται επιστροφές χρημάτων για τον συνδεδεμένο χρήστη',
  cash_forbidden: 'Δεν επιτρέπονται πληρωμές σε μετρητά με αυτό το συμβόλαιο',
  notify_new_online_order: 'Εμφάνιση ειδοποίησης κατά τη λήψη νέας ηλεκτρονικής παραγγελίας',
  print_new_online_order: 'Εκτυπώστε αυτόματα νέες ηλεκτρονικές παραγγελίες κατά τη λήψη',
  delivery_information: 'Πληροφορίες αποστολής',
  voucher_already_processed: 'Το κουπόνι έχει ήδη επεξεργαστεί',
  no_applicable_items_in_cart: 'Δεν βρέθηκαν διαθέσιμα αντικείμενα στο καλάθι',
  discount_applied: 'Εφαρμόστηκε έκπτωση',
  invalid_voucher: 'Ακυρο κουπόνι',
  allow_voucher_scan: 'Ελέγξτε για κουπόνια χρησιμοποιώντας barcode scanner',
  enable_drop_shipping: 'Ενεργοποίηση drop-shipping',
  ask_no_covers_or_takeaway: 'Να ρωτάτε πάντα τη διαμόρφωση του τραπεζιού',
  table_configuration: 'Τύπος τραπεζιού',
  num_covers: 'Αριθμός των επισκεπτών',
  takeaway: 'Takeaway',
  guests: 'Επισκέπτες',
  show_floorplan_after_sale: 'Εμφάνιση κατόψεων μετά από κάθε πώληση',
  lock_after_x_minutes: 'Κλείδωμα μετά από αδράνεια x λεπτών',
  show_deposit_items: 'Εμφάνιση αντικειμένων κατάθεσης',
  pickup: 'Παραλαβή',
  delivery: 'Delivery',
  transaction_fee: 'Τέλος συναλλαγής',
  print_description: 'Εκτύπωση περιγραφής προϊόντος στην απόδειξη',
  only_cash_for_negative_amounts: 'Μόνο μετρητά ή μη αυτόματη πληρωμή με κάρτα μπορεί να επιλεγεί όταν το ποσό είναι μικρότερο από 0',
  phone_number_example: 'Τηλέφωνο - π.χ. 306981145550',
  whatsapp_not_available_on_this_device: 'Το WhatsApp δεν έχει εγκατασταθεί σε αυτήν τη συσκευή',
  receipt_not_yet_synchronised: 'Συγχρονισμός συναλλαγών με τον server - δοκιμάστε ξανά σε λίγα λεπτά',
  notify_online_orders_with_sound: 'Αναπαραγωγή ήχου όταν λαμβάνονται ηλεκτρονικές παραγγελίες',
  restrict_online_orders_by_floor: 'Αποδεχτείτε ηλεκτρονικές παραγγελίες μόνο για συγκεκριμένα επίπεδα',
  remove_product: 'Κατάργηση προϊόντος',
  admin_access_needed: 'Αυτή η λειτουργία είναι διαθέσιμη μόνο για Διαχειριστές',
  customer_or_employee_name: 'Όνομα πελάτη ή υπαλλήλου',
  show_cart_on_left: 'Εμφάνιση καλαθιού στην αριστερή πλευρά',
  groupOrderPrintByCategory: 'Ομαδική εκτύπωση παραγγελιών ανά κατηγορία',
  handpoint_shared_secret: 'SharedSecret',
  fiscal_offline: 'Για λόγους φορολογικής συμμόρφωσης, το POS πρέπει να είναι συνδεδεμένο στο διαδίκτυο για την επεξεργασία συναλλαγών',
  unsynced_transaction_cant_reissue: 'Η συναλλαγή δεν έχει συγχρονιστεί ακόμη με τον server - δεν είναι δυνατή η επανέκδοση',
  fiscal_info: 'Δημοσιονομικές πληροφορίες',
  create_bundle: 'Δημιουργία πακέτου',
  select_items_to_bundle: 'Επιλέξτε προϊόντα για ομαδοποίηση',
  bundle_price: 'Τιμή πακέτου',
  create_bundle_items: 'Δημιουργία πακέτου προϊόντων στο καλάθι',
  bundle: 'Πακέτο',
  giftcard_or_reference_no_mandatory: 'Υποχρεωτική δωροκάρτα ή αριθμός αναφοράς',
  show_camera: ' Χρησιμοποιήστε τη κάμερα της συσκευής (εάν υπάρχει) ως scanner',
  switch_camera: 'Εναλλαγή κάμερας',
  camera: 'Kάμερα',
  print_qr_on_receipts: 'Εκτυπώστε τον κωδικό QR στις αποδείξεις',
  no_floorplans_available: 'Δεν διατίθενται κατόψεις',
  receive_online_orders_for_multiple_stores: 'Λάβετε ηλεκτρονικές παραγγελίες για πολλαπλά καταστήματα',
  inventory: 'Καταγραφή εμπορευμάτων',
  description: 'Περιγραφή',
  brand: 'Μάρκα',
  allergens: 'Αλλεργιογόνα',
  hide_total_num_items_on_order: 'Απόκρυψη συνολικού αριθμού στοιχείων στο εισιτήριο παραγγελίας',
  loss: 'Χάθηκε',
  stolen: 'Κλάπηκε',
  own_use: 'Προσωπική χρήση',
  giveaway: 'Giveaway',
  breakage: 'Θραύση',
  student_check: 'έλεγχος φοιτητικής ταυτότητας',
  overview: 'Σύνοψη',
  average: 'Mέση τιμή',
  busiest_hours: 'Πολυσύχναστες ώρες',
  rotation_time_in_secs: 'Χρόνος περιστροφής εικόνας σε δευτερόλεπτα',
  show: 'προβολή',
  uploaded: 'μεταφορτώθηκε',
  videos: 'Video',
  photos: 'Φωτογραφίες',
  display_video_or_picture: 'Εμφάνιση βίντεο, φωτογραφιών ή αρχείων pdf',
  store_logo: 'Logo καταστήματος',
  add_selected_addon: 'Προσθήκη / Κατάργηση πρόσθετων',
  add_selected_to_stores: 'Προσθήκη / Κατάργηση στα καταστήματα',
  add_selected_categories: 'Προσθήκη / Κατάργηση επιλεγμένων κατηγοριών',
  select_categories: 'Επιλέξτε κατηγορίες',
  add_selected_addon_group: 'Προσθήκη / Κατάργηση ομάδας πρόσθετων',
  company: 'Εταιρεία',
  assortment: 'Συλλογή',
  sales_history: 'Ιστορικό Πωλήσεων',
  employees: 'Υπάλληλοι',
  extensions: 'Επεκτάσεις',
  desktop: 'Desktop',
  helpdesk: 'Βοήθεια',
  specific: 'Συγκεκριμένα',
  no_data_for_this_period: 'Δεν βρέθηκαν δεδομένα για αυτή την περίοδο.',
  gross_revenue: 'Μεικτά Έσοδα',
  avg_per_transaction: 'Μέσος όρος συναλλαγής',
  from: 'από',
  select_period: 'Επιλέξτε περίοδο',
  date_start: 'Ημέρα έναρξης',
  date_end: 'Ημέρα λήξης',
  select_store: 'Επιλέξτε κατάστημα',
  select_stores: 'Επιλέξτε κατάστηματα',
  all_stores: 'Όλα τα καταστήματα',
  all_employees: 'Ολοι οι υπάλληλοι',
  all_devices: 'Όλες οι συσκευές',
  no_transactions_in_this_period: 'Δεν βρέθηκαν συναλλαγές για αυτή την περίοδο.',
  transactions_in_this_period: 'Συναλλαγές για αυτή την περίοδο.',
  report_graph: 'Report Graph',
  no_reports_found_for_this_period: 'Δεν βρέθηκαν Reports για αυτήν την περίοδο',
  top_sales: 'Top Πωλήσεις',
  top_sellers: 'Top Πωλήσεις',
  bottom_sellers: 'Χαμηλότερες πωλήσεις',
  no_top_sales_for_this_period: 'Δεν υπάρχουν Top Πωλήσεις για αυτήν την περίοδο',
  best_selling_products_for_this_period: 'Best-Selling προϊοντα για αυτήν την περίοδο',
  save_email: 'Αποθήκευση email',
  new_password: 'Νέος κωδικός',
  verify_password: 'Επαλήθευση κωδικού',
  save_password: 'Αποθήκευση κωδικού',
  contact: 'Επικοινωνία',
  name_first: 'Όνομα',
  name_last: 'Επίθετο',
  zip_code: 'Τ.Κ.',
  region: 'Περιοχή',
  billing: 'Χρέωση',
  optional: 'Προαιρετικά',
  business_name: 'Όνομα επιχείρησης',
  vat: 'ΑΦΜ',
  btw: 'BTW',
  save_profile_info: 'Αποθήκευση πληροφοριών προφίλ',
  integrations: 'Integrations',
  connect_to_your_existing: 'Συνδεθείτε με τις υπάρχουσες',
  invoices: 'Τιμολόγια',
  code: 'Κωδικός',
  isPaid: 'Πληρωμένο',
  invoice_paid: 'Πληρωμένο τιμολόγιο',
  actions: 'Ενέργειες',
  stores: 'Καταστήματα',
  updated: 'Ενημερώθηκε',
  updated_at: 'Ενημερώθηκε στις',
  create: 'Δημιουργία',
  info: 'πληροφορίες',
  currencies: 'νομίσματα',
  save_edit_details: 'Αποθήκευση και επεξεργασία λεπτομερειών',
  assigned_employees_of_this_stores: 'Υπάλληλοι σε αυτό το κατάστημα',
  not_assigned_employees: 'Δεν υπάρχουν υπάλληλοι σε αυτό το καταστημα',
  add_payment: 'Προσθήκη πληρωμής',
  payment_name: 'Όνομα πληρωμής',
  printer_name: 'Όνομα εκτυπώτη',
  edit_storess_main_details: 'Επεξεργασία λεπτομερειών καταστήματος.',
  edition: 'έκδοση',
  stores_address_details: 'Λεπτομέρειες διεύθυνσης καταστήματος',
  stores_contact_details: 'Λεπτομέρειες επικοινωνίας καταστήματος.',
  assign_employees_for_your_stores: 'Ανέθεσε υπαλλήλους στο κατάστημα.',
  create_custom_payment_methods_for_your_store: 'Δημιουργήστε προσαρμοσμένες μεθόδους πληρωμής για το κατάστημα σας.',
  devices: 'Συσκευές',
  device: 'Συσκευή',
  hardware_info: 'Πληροφορίες Hardware',
  uuid: 'UUID',
  manufacturer: 'Κατασκευαστής',
  os_name: 'OS Όνομα',
  os_version: 'OS Version',
  pos_version: 'POS Version',
  receipt_layout: 'Σχέδιο Απόδειξης',
  table_layout: 'Διάταξη Τραπεζιών',
  default_receipt: 'Προεπιλεγμένη Απόδειξη',
  email_receipt: 'Email Απόδειξη',
  change_image: 'Αλλαγή εικόνας',
  Tuesday: 'Τρίτη',
  june: 'Ιούνιος',
  your_store_address: 'Διεύθυνση του καταστήματος',
  edit: 'Επεξεργασία',
  your_text_here: 'Το κείμενο σου εδώ',
  cheap: 'Φθηνό',
  expensive: 'Ακριβό',
  client_receipt: 'Απόδειξη Πελάτη',
  transaction_agreement: 'Συμφωνία συναλλαγής',
  your_footer_message_here: 'Το υποσέλιδο μηνυμα σας εδώ',
  image: 'Εικόνα',
  price: 'Τιμή',
  edit_products_main_details: 'Επεξεργασία βασικών λεπτομεριών του προϊόντος.',
  details: 'Λεπτομέρειες',
  visible: 'Ορατό',
  in_pos: 'στο POS',
  cost: 'κόστος',
  cost_price: 'τιμή κόστους',
  delivery_price: 'Τιμή παράδοσης',
  upload_progress: 'Upload progress',
  change_products_image: 'Αλλαγή εικόνας προϊόντος',
  variants: 'Παραλλαγές',
  variant: 'Παραλλαγή',
  units: 'Μονάδες',
  alert: 'Συναγερμός',
  additional: 'Πρόσθετος',
  ledger: 'Ledger',
  subledger: 'Subledger',
  addon: 'Addon',
  addon_groups: 'Addon Groups',
  addon_group: 'Addon Group',
  add_delete_and_edit_products_variants: 'Προσθήκη, διαγραφή και επεξεργασία παραλλαγών του προϊόντος.',
  assing_product_to_store: 'Ανάθεση προϊόντος σε κατάστημα (εάν δεν έχει επιλεγεί κανένα κατάστημα, το προϊόν θα εμφανίζεται σε κάθε κατάστημα).',
  products_addtional_fields: 'Πρόσθετα πεδία του προϊόντος.',
  manage_addons_group: 'Addons Group',
  sold_by_weight: 'Πώληση κατά βάρους',
  dynamic_price: 'Δυναμική Τιμή',
  dynamic: 'Δυναμική Τιμή',
  min_age: 'Ελάχιστη ηλικία',
  packaging: 'Συσκευασία',
  contents: 'περιεχόμενα',
  form: 'μορφή',
  products_packaging_and_suppliers_details: 'Συσκευασία του προϊόντος και λεπτομέρειες προμηθευτών.',
  stock: 'Απόθεμα',
  management: 'Διαχείριση',
  count: 'μέτρημα',
  filter_name: 'Πληκτρολογήστε για να φιλτράρετε τη στήλη ονόματοσ...',
  find_products_category: 'Βρείτε προϊόντα ανά κατηγορία',
  find_products_addons: 'Βρείτε προϊόντα ανά addons',
  in_stock: 'Σε απόθεμα',
  order_below: 'Παραγγελία κάτω από',
  order_quantity: 'Ποσότητα παραγγελίας',
  advance: 'Advance',
  view_history: 'Προβολή ιστορικού',
  click_here_to_select_source: 'Πατήστε εδώ για να επιλέξετε λόγο',
  record_missing_item: 'Καταχώρηση ελλειπών αντικειμένων',
  lost: 'Χαμένα',
  current_stock: 'Τρέχον απόθεμα',
  current_report: 'Τρέχον report',
  view_stock_history: 'Προβολή ιστορικού αποθέματος',
  color: 'Χρώμα',
  duplicate_item: 'Αντίγραφο αντικειμένου',
  want_duplicate_item: 'Θέλετε να αντιγράψετε αυτό το αντικείμενο;',
  delete_item: 'Διαγραφή αντικειμένου',
  want_delete_item: 'Θέλετε να διαγράψετε αυτό το αντικείμενο;',
  categories_visibility_changed: 'Ορατότητα της κατηγορίας άλλαξε',
  change_visibility: 'Αλλαγή ορατότητας',
  category: 'Κατηγορία',
  parent: 'Γονέας',
  select: 'Επιλέξτε',
  rate: 'Τιμή',
  delete_selected_items: 'Διαγραφή επιλεγμένων αντικειμένων',
  aplly_diff_rates: 'Θέλετε να εφαρμόσετε διαφορετικό φορολογικό συντελεστή για διαφορετικές χώρες;',
  countries_added: 'Χώρες που προστέθηκαν',
  groups: 'Γκρουπς',
  group: 'Γκρουπ',
  standard: 'Προκαθαρισμένο',
  default_text: 'Προεπιλεγμένο κείμενο',
  business_rules: 'Επιχειρηματικοί Κανόνες',
  business_rule: 'Επιχειρηματικός Κανόνας',
  title: 'Τίτλος',
  is_active: 'είναι ενεργό',
  startdate: 'ημερομηνία έναρξης',
  enddate: 'ημερομηνία λήξης',
  rules: 'Κανόνες',
  rule: 'Κανόνας',
  edit_business_rules_main_details: 'Επεξεργαστείτε τα βασικά στοιχεία του κανόνα επιχείρησης.',
  active: 'Ενεργό',
  time_start: 'Ώρα έναρξης',
  time_end: 'Ώρα λήξης',
  select_specific_days: 'Επιλέξτε συγκεκριμένες ημέρες',
  conditions: 'Συνθήκες',
  condition: 'Συνθήκη',
  change_business_rules_conditions: 'Αλλαγή των όρων των επιχειρηματικών κανόνων.',
  change_business_rules_outcome: 'Αλλαγή του αποτελέσματος του επιχειρηματικού κανόνα.',
  minimum_amount: 'Ελάχιστο ποσό',
  minimum: 'Ελάχιστο',
  maximum: 'Μέγιστο',
  maximum_amount: 'Μέγιστο ποσό',
  ignore_for: 'Αγνοήστε για',
  outcome: 'Αποτέλεσμα',
  voucher_outcome: 'αποτέλεσμα voucher',
  new_price_rule: 'Νέα τιμή για επιχειρηματικούς κανόνες',
  min_pack_value: 'Ελάχιστη τιμή πακέτου',
  max_pack_value: 'Μέγιστη τιμή πακέτου',
  enable: 'Ενεργοποίηση κανόνων',
  disable: 'Απενεργοποιήστε τους κανόνες',
  all: 'Όλα',
  update_time: 'Ενημέρωση χρόνου έναρξης / λήξης',
  update_date: 'Ενημέρωση ημερομηνίας έναρξης / λήξης',
  catalog_order: 'Σειρά καταλόγου',
  drag_and_drop_items_to_reorder: 'Σύρετε και αποθέστε στοιχεία για αναδιάταξη',
  see_reports: 'Μπορείτε να δείτε τις αναφορές σας στον πίνακα ελέγχου επιλέγοντας',
  generate_report: 'Δημιουργία αναφοράς',
  or: 'ή',
  send_report: 'Μπορείτε να στείλετε ανεπεξέργαστα δεδομένα στο email σας',
  send_report_button: 'Αποστολή αναφοράς',
  send_to: 'Αποστολή σε',
  recipient: 'παραλήπτης',
  available_reports: 'Διαθέσιμες αναφορές για αυτή τη χρονική περίοδο',
  select_currency_text: 'Επιλέξτε ένα νόμισμα ή ένα συγκεκριμένο κατάστημα.',
  tables: 'Τραπέζια',
  revenue: 'Έσοδα',
  see_revenue: 'Δείτε το ακαθάριστο εισόδημά σας, το κόστος, τους φόρους και το κέρδος',
  net_revenue: 'Καθαρά έσοδα',
  profit: 'Κέρδος',
  see_transaction: 'Δείτε τη μέθοδο της συναλλαγής σας, τον αριθμό και το συνολικό ποσό',
  see_payments: 'Δείτε τη μέθοδο πληρωμής, τον αριθμό και το συνολικό ποσό',
  see_refunds: 'Δείτε τη μέθοδο επιστροφής χρημάτων, τον αριθμό και το συνολικό ποσό',
  see_taxes: 'Δείτε τους Φόρους σας, τον Αριθμό και το Συνολικό Ποσό',
  brands: 'Μάρκες',
  channels: 'Κανάλια',
  channel: 'Κανάλι',
  see_brands: 'Δείτε τα προϊόντα σας ανά εμπορικό σήμα, τον αριθμό και το συνολικό ποσό',
  see_channels: 'Δείτε τα προϊόντα σας ανά κανάλι σήμα, τον αριθμό και το συνολικό ποσό',
  see_categories: 'Δείτε τις κατηγορίες σας, τον αριθμό και το συνολικό ποσό',
  click_details: 'Κάντε κλικ εδώ για να δείτε λεπτομέρειες',
  report_select_other_period: 'Επιλέξτε άλλη χρονική περίοδο για να δείτε την διαθέσιμη αναφορά',
  created: 'Δημιουργήθηκε',
  discounts: 'Εκπτώσεις',
  add_discount: 'Προσθέστε έκπτωση',
  discount_percent: 'Ποσοστό έκπτωσης',
  register_opertations: 'Εγγραφή Επιχειρήσεων',
  operations: 'Επιχειρήσεις',
  first: 'Πρώτο',
  last: 'Τελυταίο',
  phone: 'Τηλέφωνο',
  permission_to_contact: 'Άδεια για επικοινωνία',
  identifiers_type: 'Τύπος αναγνωριστικού πελάτη',
  identifiers_value: 'Τιμή αναγνωριστικού',
  shipping: 'Αποστολή',
  diff_billing: 'αν είναι διαφορετική από την χρέωση',
  rights: 'Δικαιώματα',
  employee: 'Υπάλληλος',
  token: 'Token',
  uninstall_ext: 'Απεγκατάσταση επέκτασης',
  active_ext: 'Η επέκταση είναι ενεργής',
  subscribe: 'Εγγραφείτε',
  remove_ext_msg: 'Είστε σίγουροι ότι θέλετε να καταργήσετε αυτήν την επέκταση Are you sure you want to remove this extension;',
  no_thanks: 'Όχι ευχαριστώ',
  subs_to_ext: 'Εγγραφείτε στις επεκτάσεις',
  period: 'Περίοδος',
  payment_details: 'Λεπτομέρειες πληρωμής',
  card_name: 'Όνομα στην κάρτα:',
  paymnet_method: 'Μέθοδος πληρωμής:',
  code_country: 'Κωδικός χώρας:',
  diff_way_pay_msg: 'Ή επιλέξτε άλλο τρόπο πληρωμής',
  phone_support: 'Τηλεφωνική Υποστήριξη',
  support_msg: 'Επικοινωνήστε με την εξυπηρέτηση πελάτων για βοήθεια :',
  email_support: 'E-mail Υποστήριξης',
  support_email: 'E-mail Υποστήριξης:',
  info_email: 'E-mail Πληροφοριών:',
  sales_email: 'E-mail Πωλήσεων:',
  page: 'σελίδα',
  dark_theme: 'Σκοτεινό Θέμα',
  report_spec_time: 'Δημιουργήστε report με συγκεκριμένη ώρα',
  vat_btw_no: 'ΑΦΜ No.',
  store_unable_delete: 'Δεν είναι δυνατή η διαγραφή του καταστήματος επειδή μια συσκευή είναι συνδεδεμένη. Καταργήστε τη συσκευή για να διαγράψετε το κατάστημα.',
  important: 'Σηματικό:',
  store_device_delete_msg: 'Βεβαιωθείτε ότι έχετε αποσυνδεθεί από αυτήν τη συσκευή πριν τη διαγράψετε.',
  email_test_receipt: 'Στείλτε δοκιμαστική απόδειξη',
  merchant: 'Merchant',
  per_item: 'ανά αντικείμενο',
  img_width_msg: 'Το μέγιστο επιτρεπόμενο μέγεθος αρχείου είναι 250px.',
  err_img_msg: 'Σφάλμα: Το πλάτος της εικόνας δεν πρέπει να υπερβαίνει τα 250px',
  rules_actions: 'Κανόνες ενεργειών',
  enable_all: 'Ενεργοποιήστε όλους τους επιχειρηματικούς κανόνες',
  want_enable_all: 'Θέλετε να ενεργοποιήσετε όλους τους επιχειρηματικούς κανόνες;',
  disable_all: 'Απενεργοποιήστε όλους τους επιχειρηματικούς κανόνες',
  want_disable_all: 'Θέλετε να απενεργοποιήσετε όλους τους επιχειρηματικούς κανόνες;',
  product_cat_error: 'Παρακαλώ επίλεξτε μια κατηγορία',
  field_not_empty: 'Το πεδίο δεν πρέπει να είναι κενό.',
  drop_files: 'Σύρετε το αρχείο σας εδώ για να ανεβάσετε την εικόνα',
  save_variant_before_stock: 'Αποθηκεύστε την νέα παραλλαγή του προϊοντος πριν επεξεργαστείτε το απόθεμα σας.',
  no_employees: 'Δεν έχετε υπαλλήλους. Μπορείτε να δημιουργήσετε νέους υπαλλήλους',
  here: 'εδώ',
  no_stock: 'Δεν υπάρχουν αποθέματα σε αυτό το κατάστημα',
  product_exists: 'Αυτό το προϊόν υπάρχει μόνο σε',
  add_units_stock: 'Προσθέστε μονάδες στο απόθεμα',
  remove_units_stock: 'Αφαιρέστε μονάδες από το απόθεμα',
  saving: 'Αποθήκευση',
  transfer_units: 'Προσθέσε αριθμό μονάδων για μεταφορά',
  remove_units_store: 'Προσθέστε αριθμό μονάδων που θέλετε να αφαιρέσετε',
  notification: 'ειδοποίηση',
  set_new_start_end_time: 'Ορίστε νέα ώρα έναρξης/λήξης',
  set_new_start_end_date: 'Ορίστε νέα ημερομηνία έναρξης / λήξης',
  by_email: 'με email',
  charts_graphs: 'Charts/Graphs',
  compare_reports: 'Συγκρίνετε reports',
  new_report: 'Νέο Report',
  basic: 'Βασικό',
  no_acount_to_send_report: 'Δεν μπορείτε να στείλετε αυτήν την αναφορά γιατί ο λογαριασμός σου είναι',
  upgrade_account_msg: 'Πρέπει να αναβαθμίσετε τον λογαριασμό σας!',
  gen_report_msg: 'Μπορείτε να δημιουργήσετε την αναφορά σας και να δείτε τα αποτελέσματα στον πίνακα ελέγχου',
  disabled_report: 'Reports είναι απενργοποιημένα λόγο εκκρεμών πληρωμών',
  please_check: 'Παρακαλώ ελέξτε',
  email_wrong_format: 'Η μορφή του Email δεν είναι σωστή.',
  bar_chart: 'Bar Chart',
  method: 'Μέθοδος',
  total_taxed_amount: 'Συνολικό φορολογημένο ποσό',
  total_price_product: 'Συνολική τιμή των προϊόντων',
  enter_value: 'Πρέπει να εισάγετε μια τιμή',
  not_valid_mail: 'Μη έγκυρο email',
  enter_email: 'Προσθέστε το email σας',
  unit_price: 'Τιμή Μονάδας',
  extras: 'Εξτρά',
  monthly_billed: 'χρεώνεται μηνιαίως',
  removing_ext: 'Αυτή η επέκταση θα αφαιρεθεί από τον επόμενο κύκλο χρέωσης',
  local_ip: 'Local IP',
  printer_ip_ad: 'Διεύθυνση ip εκτυπωτή',
  printer_categories: 'Κατηγορίες εκτυπωτή',
  printers: 'Εκτυπωτές',
  payment_processor: 'Επξεργαστής πληρωμών',
  question: 'Έχετε κάποια ερώτηση ?',
  check_here: 'Ελέξτε εδώ',
  knowledge_center: 'Κέντρο γνώσης.',
  support_articles_msg: 'Έχουμε πολλά άρθρα υποστήριξης που μπορούν να σας βοηθήσουν να ξεκινήσετε, να δημιουργήσετε προϊόντα, να συνδέσετε το ηλεκτρονικό σας κατάστημα και πολλά άλλα...',
  support_ques: 'Εάν ακόμα δεν βρίσκετε την απάντηση που θέλετε, μπορείτε να έρθετε',
  in_touch: 'σε επικοινωνία μαζί μας',
  select_floorplan: 'Επιλέξτε Κάτοψη',
  floors: 'Δάπεδα',
  add_floorplan: 'Προσθέστε κάτοψη',
  delete_floorplan: 'Διαγραφή κάτοψης',
  floorplan: 'Κάτοψη',
  add_tables: 'Προσθήκη τραπεζιών',
  sign_up_msg: 'Χιλιάδες επιχειρήσεις έχουν ενταχθεί στην Countr. Σήμερα είναι η σειρά σας. Εγγραφειτε δωρεάν.',
  user_required: 'Όνομα χρήστη (Username) απαιτείται.',
  name_required: 'Όνομα απαιτείται',
  company_name_required: 'Όνομα επιχείρησης απαιτείται.',
  phone_required: 'Τηλέφωνο απαιτείται.',
  pass_required: 'Κωδικός απαιτείται.',
  already_account: 'Έχετε ήδη λογαριασμό;',
  accept_sign_up: 'Με την εγγραφή δέχομαι τους',
  terms_conditions: 'Ορους και συνθήκες',
  policy: 'Πολιτική Απορρήτου',
  licence_agree: 'Συμφωνία Άδειας',
  your_name: 'Το όνομα σας',
  company_web: 'Website επιχείρησης (δεν είναι υποχρεωτικό)',
  forgot_pass: 'Ξεχάσατε τον κωδικό?',
  login: 'Σύνδεση',
  no_account_link: 'Δεν έχετε λογαριασμό;',
  email_required: 'Email απαιτείται.',
  send_link: 'Στείλτε λίνκ',
  download_file: 'Κατέβασμα αρχείου',
  account_number: 'Αριθμός λογαριασμού',
  me_rules: 'Κανόνων',
  by_volume: 'Τα κορυφαία προϊόντα πώλησης (ανά ποσότητα) αυτήν την περίοδο',
  number_sold: 'Αριθμός πωλήσεων',
  by_volume_bottom: 'Τα προϊόντα που πωλούνται στο κάτω μέρος (ανά ποσότητα) αυτήν την περίοδο',
  create_compare_report: 'Δημιουργήστε μια νέα αναφορά για σύγκριση',
  last_per: 'Προηγούμενος(η)',
  no_per_data: 'δεν έχει διαθέσιμες αναφορές',
  create_new_rep: 'Δημιουργία νέας αναφοράς',
  reset_pass_text: 'Εισαγάγετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου για να επαναφέρετε τον κωδικό πρόσβασης',
  enter_bus_info: 'Καταχωρίστε τις πληροφορίες της επιχείρησής σας',
  organization: 'Όνομα Οργανισμού',
  mandatory_fields: 'Όλα τα πεδία είναι υποχρεωτικά !!!',
  invalid_eu: 'Μη έγκυρος αριθμός ΦΠΑ για τη χώρα της ΕΕ',
  trial_expires: 'Η δοκιμή σας λήγει',
  select_package: 'Επιλέξτε πακέτο',
  excluse_vat: 'χωρίς ΦΠΑ',
  instead: 'αντί',
  save_payment: 'Αποθηκεύστε αυτήν τη μέθοδο πληρωμής για μελλοντικές συναλλαγές',
  procced_paymet: 'Προχωρήστε στην πληρωμή',
  selected_package: 'Επιλεγμένο πακέτο',
  selected_ext: 'Επιλεγμένες επεκτάσεις',
  old_dashboard: 'Επιστροφή στον Πίνακα ελέγχου παλαιού τύπου',
  subscription_msg: 'Συγχαρητήρια, είστε έτοιμος να πατέ ζωντανά με το κατάστημά σας! Οι συναλλαγές σας θα αποθηκευτούν για πάντα και όλες οι δοκιμές θα εξαφανιστούν. Προτού πάτε ζωντανά, βεβαιωθείτε ότι έχετε επιλέξει τις επεκτάσεις που θέλετε να συνεχίσετε να χρησιμοποιείτε.',
  transfer_from: 'Μεταφορά μονάδων από',
  customer_purchase: 'Αγορά πελατών',
  total_spend: 'Συνολικά έξοδα',
  last_customers_transactions: 'Οι τελευταίες συναλλαγές του πελάτη',
  email_recipient: 'Email παραλήπτης',
  show_revenue: 'Παρουσίαση εσόδων',
  show_sales: 'Παρουσίαση πωλήσεων',
  visits: 'Σύνολο επισκέψεων',
  select_action: 'Επιλέξτε ενέργεια',
  show_receipt: 'Εμφάνιση απόδειξης',
  set_values_for: 'Ορίστε τιμές για',
  update: 'Update',
  select_addons_group: 'Επιλέξτε ομάδα Addons',
  select_addon: 'Επιλέξτε πρόσθετο',
  all_addon_groups_are_in_use: 'Η λίστα είναι κενή, χρησιμοποιούνται όλες οι ομάδες Addons',
  store_hours: 'Ωρες λειτουργίας',
  opening_hours: 'Ωρες λειτουργίας',
  no_opening_hours: 'Χωρίς ώρες λειτουργίας',
  set_opening_hours: 'Ορίστε τις ώρες λειτουργίας',
  delivery_hours: 'Ώρες παράδοσης',
  no_delivery_hours: 'Χωρίς ώρες παράδοσης',
  set_delivery_hours: 'Ορίστε ώρες παράδοσης',
  ordering_hours: 'Ώρες παραγγελίας',
  no_ordering_hours: 'Χωρίς ώρες παραγγελίας',
  set_ordering_hours: 'Ορίστε ώρες παραγγελίας',
  takeaway_hours: 'Ώρες πακέτο',
  no_takeaway_hours: 'Χωρίς ώρες πακέτο',
  set_takeaway_hours: 'Ρυθμίστε τις ώρες πακέτου',
  delivery_zones: 'Ζώνες παράδοσης',
  no_delivery_zones: 'Χωρίς ζώνες παράδοσης',
  set_delivery_zones: 'Ορισμός ζωνών παράδοσης',
  Monday: 'Δευτέρα',
  Thursday: 'Πέμπτη',
  Friday: 'Παρασκευή',
  Saturday: 'Σάββατο',
  Sunday: 'Κυριακή',
  set_opening_store: 'Ορίστε τις ώρες λειτουργίας για το κατάστημα',
  set_delivery_store: 'Ορίστε ώρες παράδοσης για το κατάστημα',
  set_ordering_store: 'Ορίστε ώρες παραγγελίας για το κατάστημα',
  set_takeaway_store: 'Ρυθμίστε τις ώρες πακέτου για το κατάστημα',
  set_zones_store: 'Ορισμός ζωνών παράδοσης για το κατάστημα',
  no_data_added: 'No data added',
  add_hours: 'Προσθήκη ωρών',
  add_zones: 'Προσθήκη ζωνών παράδοσης',
  order_sub: 'Υποκατηγορίες',
  new_item: 'νέας',
  tips: 'φιλοδώρημα',
  see_busiest_hours: 'Δείτε τις πιο πολυσύχναστες ώρες με βάση την περίοδο που επιλέξατε',
  see_employee_transactions: 'Δείτε συναλλαγές ανά εργαζόμενο για την επιλεγμένη περίοδο',
  see_employee_tips: 'Δείτε φιλοδώρημα ανά εργαζόμενο για την επιλεγμένη περίοδο',
  see_employee_refunds: 'Δείτε τις επιστροφές ανά υπάλληλο για την επιλεγμένη περίοδο',
  see_customer_transactions: 'Δείτε συναλλαγές ανά τύπο πελάτη για την επιλεγμένη περίοδο',
  see_customer_refunds: 'Δείτε επιστροφές χρημάτων ανά τύπο πελάτη για την επιλεγμένη περίοδο',
  new_customer: 'Νέος πελάτης',
  returning_customer: 'Πελάτης που επιστρέφει',
  no_customer: 'Δεν υπάρχουν δεδομένα πελατών',
  reps_waste: 'Reps and waste',
  see_reps_waste: 'See reps and waste by reason',
  see_employee_reps_waste: 'See reps and waste by employee',
  employee_report_graph: 'Δείτε πωλήσεις υπαλλήλων ανά ώρα',
  not_sold: 'Δεν πουλήθηκε',
  non_selling_products_this_period: 'Προϊόντα που δεν πωλούν αυτήν την περίοδο (Προϊόντα με 0 πωλήσεις)',
  compare_stores: 'Συγκρίνετε καταστήματα',
  average_amount_spent: 'Μέσο ποσό που δαπανήθηκε',
  average_amount_refunded: 'Επιστρέφεται το μέσο ποσό',
  bundle_parts: 'Μέρη προϊόντος',
  create_giftcard: 'Δημιουργία δωροκάρτας',
  giftcards: 'Δωροκάρτες',
  select_graphs_type: 'Επιλέξτε τύπο γραφήματος',
  see_discount_percentage: 'Δείτε συναλλαγές με ποσοστό έκπτωσης',
  see_discount_by_product_percentage: 'Δείτε την έκπτωση που δίνεται στα προϊόντα (οι αριθμητικές εκπτώσεις υπολογίζονται ως ποσοστό)',
  discount_percentage: 'Έκπτωση (ποσοστό)',
  discount_numeric: 'Έκπτωση (αριθμητική)',
  see_discount_numeric: 'Δείτε συναλλαγές με αριθμητική έκπτωση',
  number_of_discounts: 'και τον αριθμό των φορών που πραγματοποιήθηκε αυτή η έκπτωση σε επίπεδο προϊόντος ή καλαθιού αγορών',
  delivery_cost: 'Κόστος παράδοσης',
  total_cash_after_tips_and_change: 'Σύνολο μετρητών μετά από φιλοδωρήματα και ρέστα',
  assigned_stores: 'Ανατεθειμένα καταστήματα',
  chat_iframe: 'Chat',
  mobile_phone: 'Κινητό τηλέφωνο',
  notifications: 'Ειδοποιήσεις',
  annualy_billed: 'χρεώνεται ετησίως',
  track_stock: 'Παρακολούθηση αποθεμάτων',
  duplicate: 'Αντιγραφή',
  assigned_groups: 'Ανατεθείσες ομάδες',
  extension: 'Επέκταση',
  vouchers: 'Κουπόνια',
  show_open_carts_from_store: 'Εμφάνιση ανοικτών παραγγελιών από το κατάστημα',
  ean: 'Barcode (EAN)',
  sku: 'SKU',
  want_delete_all_items: 'Θέλετε να διαγράψετε όλα τα επιλεγμένα στοιχεία;',
  want_to_change_visibility: 'Θέλετε να αλλάξετε την ορατότητα σε όλα τα επιλεγμένα στοιχεία;',
  import_export: 'Εισαγωγή/Εξαγωγή',
  methods: 'Μέθοδοι Πηρωμής',
  stored_payment_info: 'Δείτε τα στοιχεία του τρόπου πληρωμής σας',
  stored_payment: 'Αποθηκευμένοι τρόποι πληρωμής',
  report_spec_zone: 'Επιλέξτε διαφορετική ζώνη ώρας',
  no_stock_stores: 'Δεν έχετε κανένα κατάστημα. Δημιουργήστε ένα κατάστημα και εκχωρήστε προϊόντα στο απόθεμα.',
  create_custom_payment: 'Δημιουργήστε προσαρμοσμένους τρόπους πληρωμής για το κατάστημά σας.',
  min_chars: 'τουλάχιστον 3 χαρακτήρες',
  enable_deposit: 'Ενεργοποίηση κατάθεσης',
  enable_daily_report: 'Ενεργοποίηση ημερήσιας αναφοράς',
  reorder_email: 'Email αναδιάταξης αποθέματος',
  enable_non_revenue: 'Ενεργοποίηση μη εσόδων',
  total_visits: 'Συνολικές επισκέψεις',
  how_it_works: 'Πως δουλεύει',
  info_text_catalog_order_one: '• Μπορείτε να μεταφέρετε και να αποθέσετε τα προϊόντα σας τοποθετώντας τον κέρσορα πάνω από ένα στοιχείο και κρατώντας πατημένο το αριστερό κουμπί του ποντικιού.',
  info_text_catalog_order_two: '• Για να τοποθετήσετε ένα προϊόν σε διαφορετική σελίδα θα πρέπει να τοποθετήσετε τον κέρσορα πάνω από το προϊόν, κάντε δεξί κλικ και επιλέξτε τη σελίδα στην οποία θέλετε να το στείλετε.',
  verify_dialog: 'Ένας κωδικός επαλήθευσης έχει σταλεί στη διεύθυνση e-mail σας.',
  verify_proceed_dialog: 'Επιλέξτε έναν τύπο κλάδου της επιχείρησής σας και, στη συνέχεια, προχωρήστε στην επαλήθευση του λογαριασμού σας.',
  export_customer: 'Εξαγωγή πληροφοριών πελατών',
  disabled_floor: 'Το πάτωμα είναι απενεργοποιημένο',
  filter_by_category: 'Φιλτράρισμα ανά Κατηγορία',
  current_timezone: 'Τοπική ζώνη ώρας:',
  important_note: 'Σημαντική σημείωση:',
  transaction_note: 'Δεν θα χρεωθείτε, αλλά χρειαζόμαστε τα στοιχεία πληρωμής σας για να μπορέσουμε να επικυρώσουμε το αίτημά σας για να ξεκινήσει.',
  translate_name: 'Ορίστε μετάφραση για τις επιλεγμένες γλώσσες',
  translate_tool: 'Δώστε μετάφραση στο όνομα',
  payment_method_note: 'Μπορείτε να διαγράψετε μόνο τον τρόπο πληρωμής και όχι να προσθέσετε ή να επεξεργαστείτε. Μπορείτε να προσθέσετε έναν νέο τρόπο πληρωμής ενώ πληρώνετε',
  add_new_payment: 'ή προσθήκη νέου',
  no_payment_method: 'Δεν υπάρχει αποθηκευμένος τρόπος πληρωμής',
  daily_report: 'Καθημερινή ώρα αναφοράς (24ωρο ρολόι)',
  it_seems_store: 'Φαίνεται κατάστημα',
  no_stock_items: 'δεν έχει αρκετά είδη σε απόθεμα. Εισαι σιγουρος οτι θελεις να συνεχισεις?',
  export_file_finished: 'Το αρχείο εξαγωγής ολοκληρώθηκε, εδώ είναι ο σύνδεσμος λήψης:',
  import_file_finished: 'Το αρχείο εισαγωγής ολοκληρώθηκε με επιτυχία. Σύνολο προϊόντων:',
  stock_import_file_finished: 'Το αρχείο εισαγωγής αποθεμάτων ολοκληρώθηκε με επιτυχία.',
  error_stock_finished: 'Παρουσιάστηκε σφάλμα. Ελέγξτε το αρχείο σας για τυχόν λάθος δεδομένα',
  import_file_init: 'Επεξεργαζόμαστε το αρχείο σας. Θα λάβετε μια ειδοποίηση όταν είναι έτοιμη.',
  export_file_init: 'Επεξεργαζόμαστε το αρχείο σας. Θα λάβετε μια ειδοποίηση όταν είναι έτοιμη.',
  create_voucher: 'Δημιουργία κουπονιού',
  total_uses: 'Χρησιμοποιούμενο ποσό',
  scope: 'Πεδίο εφαρμογής',
  numeric_discount: 'Αριθμητική έκπτωση',
  start_date: 'Ημερομηνία έναρξης',
  expiry_date: 'Ημερομηνία λήξης',
  products_categories: 'Προϊόντα ή Κατηγορίες',
  search_products: 'Αναζήτηση προϊόντων',
  selected_products: 'Επιλεγμένα προϊόντα',
  search_categories: 'Αναζήτηση κατηγοριών',
  selected_categories: 'Επιλεγμένες κατηγορίες',
  selected_items: 'Επιλεγμένα στοιχεία',
  local_prices: 'Τοπικές τιμές',
  translate: 'Μεταφράζω',
  value: 'Αξία',
  code_type: 'Κωδικός είδους',
  non_revenue: 'Μη έσοδα',
  giftcard_product_alert: 'Το προϊόν της δωροκάρτας πρέπει να είναι πάντα ένα προϊόν χωρίς επαναπροσδιορισμό.',
  multi_countries_tax_rules: 'Φορολογικοί συντελεστές σε πολλές χώρες',
  countries_rates: 'Τα ποσοστά των χωρών',
  edit_store_themes: 'Επεξεργασία θεμάτων καταστήματος (Διατίθεται μόνο για Countr Kiosk)',
  themes: 'Θέματα',
  font: 'Γραμματοσειρά',
  current_theme: 'Τρέχον θέμα',
  add_theme: 'Προσθήκη θέματος',
  delete_theme: 'Διαγραφή θέματος',
  body_background: 'Χρώμα του φόντου',
  body_text: 'Χρώμα κειμένου',
  primary_text: 'Κύριο χρώμα κειμένου',
  secondary_text: 'Δευτερεύον χρώμα κειμένου',
  header: 'Χρώμα φόντου κεφαλίδας',
  header_text: 'Χρώμα κειμένου κεφαλίδας',
  toolbar: 'Χρώμα φόντου γραμμής εργαλείων',
  toolbar_text: 'Χρώμα κειμένου γραμμής εργαλείων',
  button_primary_text: 'Χρώμα κειμένου κύριου κουμπιού',
  button_primary_background: 'Χρώμα φόντου κύριου κουμπιού',
  button_secondary_text: 'Χρώμα κειμένου δευτερεύοντος κουμπιού',
  button_secondary_background: 'Δευτερεύον χρώμα φόντου κουμπιού',
  button_neutro_text: 'Χρώμα κειμένου ουδέτερου κουμπιού',
  button_neutro_background: 'Χρώμα φόντου του ουδέτερου κουμπιού',
  isActive: 'είναι Ενεργό',
  Wednesday: 'Τετάρτη',
  answered: 'απάντησε',
  common: {"welcome": "Γεια σας {{name}}, έχετε {{count}} νέα {{μηνύματα}}!"},
  username: 'όνομα χρήστη',
  show_price: 'Εμφάνιση Τιμής',
  companyid: 'Business name',
  invalid_merchantid: 'Μη έγκυρο άτομο επικοινωνίας',
  invalid_businessid: 'Μη έγκυρο όνομα επιχείρησης',
  all_categories: 'Ολες οι κατηγορίες',
  close_search_product: 'Κλείσιμο αναζήτησης και κατάργηση φίλτρου αναζήτησης',
  firstid: 'Ονομα',
  lastid: 'Επίθετο',
  storeid: 'Όνομα καταστήματος',
  enter_a_printerid: 'Εισαγάγετε ένα όνομα εκτυπωτή',
  enter_orderid: 'Εισαγάγετε το όνομα του καλαθιού',
  enter_tableid: 'Εισαγάγετε το όνομα του πίνακα',
  customerid: 'Πελάτης',
  insert_printerid: 'Εισαγάγετε ένα όνομα (3 χαρακτήρες ή περισσότερο) για τον εκτυπωτή σας.',
  not_paid: 'Απληρωτος',
  menu_itemid: 'Όνομα στοιχείου μενού (έως 12 χαρακτήρες)',
  employeeid: 'Υπάλληλος',
  deviceid: 'Συσκευή',
  addid_device: 'Ονομάστε τη συσκευή σας',
  deviceid_mandatory: 'Το όνομα της συσκευής είναι υποχρεωτικό',
  no_floorplans: 'Δεν διατίθενται κατόψεις',
  table_layout_advice: 'Δημιουργήστε μια κάτοψη στο back-office για να χρησιμοποιήσετε αυτήν τη δυνατότητα',
  merge_tables: 'Συγχώνευση τραπεζιών',
  custom_item_not_valid: 'Το προσαρμοσμένο στοιχείο δεν είναι έγκυρο',
  no_connection_sync: 'Για να συγχρονίσετε όλες τις συναλλαγές, βεβαιωθείτε ότι έχει αποκατασταθεί η σύνδεση πριν βγείτε από την εφαρμογή.',
  new_device_name: 'Νέο όνομα συσκευής',
  change_device_name: 'Αλλαγή ονόματος συσκευής',
  change_store: 'Αλλαγή καταστήματος',
  alert_change_store: 'Θα χρειαστεί να κλείσετε τελείως και να επανεκκινήσετε την εφαρμογή για να εφαρμόσετε αυτήν την αλλαγή',
  customer_total_spend: 'Συνολικές δαπάνες πελατών',
  skip_payment_success_info: 'Μην εμφανίζετε πληροφορίες επιτυχίας πληρωμής',
  cart_always_open: 'Να εμφανίζεται πάντα το καλάθι στη δεξιά πλευρά της οθόνης',
  show_calculator: 'Εμφάνιση αριθμομηχανής',
  selected: 'Επιλεγμένο',
  categories_tiles_position: 'Θέση πλακιδίων κατηγορίας',
  kiosk_mode: 'Λειτουργία Kiosk',
  kiosk_mode_disabled: 'Η λειτουργία Kiosk είναι απενεργοποιημένη',
  change_table: 'Αλλαγή ονόματος τραπεζιού',
  change_cart: 'Αλλαγή ονόματος καροτσιου',
  change_cart_tip: 'Θα πρέπει να έχει τουλάχιστον 2 γράμματα',
  a4_paper: 'Χρησιμοποιήστε χαρτί Α4 για εκτύπωση',
  auto_print: 'Αυτόματη εκτύπωση απόδειξης μετά την επιτυχία της πληρωμής',
  select_report: 'Επιλέξτε τύπο αναφοράς',
  report_not_found: 'Η αναφορά δεν βρέθηκε',
  new_cart: 'Νέο καλάθι',
  new_table: 'Νέο τραπέζι',
  delete_cart: 'Διαγραφή καλαθιού',
  delete_cart_confirmation: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το καλάθι;',
  product_tile_size: 'Αυξήστε το μέγεθος πλακιδίων προϊόντος',
  load_more_transactions: 'Φορτώστε περισσότερες συναλλαγές',
  select_variant: 'Επιλέξτε παραλλαγή',
  remember_password: 'Να θυμάσαι τον κωδικό',
  go_dashboard: 'Μεταβείτε στο Dashboard μου',
  enable_cart_item_remove: 'Ενεργοποίηση διαγραφής στοιχείου στο καλάθι',
  remove_item: 'Αφαίρεση στοιχείου',
  show_current_cart: 'Εμφάνιση τρέχοντος καλαθιού',
  show_carts: 'Εμφάνιση λίστας καλαθιών',
  show_tables: 'Εμφάνιση πλάνου δαπέδου',
  open_receipt: 'Ανοιχτή απόδειξη',
  kds: 'Οθόνη κουζίνας',
  archive: 'Αρχείο',
  send_archive: 'Αποστολή στο αρχείο',
  awaiting_payment: 'awaiting payment',
  all_items: 'Ολα τα αντικείμενα',
  printed: 'Νέος',
  pending: 'Νέος',
  preparing: 'Προετοιμασία',
  ready: 'Ετοιμος',
  completed: 'Ολοκληρώθηκε',
  send_new: 'Αποστολή σε νέο',
  send_progress: 'Αποστολή σε εξέλιξη',
  send_ready: 'Αποστολή στο έτοιμο',
  send_completed: 'Αποστολή στο ολοκληρωμένο',
  active_orders: 'Ενεργές παραγγελίες',
  all_orders: 'Όλες οι παραγγελίες',
  select_employee: 'Επιλέξτε Υπάλληλο',
  select_status: 'Επιλέξτε Κατάσταση',
  send_all_to_status: 'Αποστολή όλων των προϊόντων στην κατάσταση',
  no_open_orders: 'Δεν υπάρχουν διαθέσιμες παραγγελίες',
  click_refresh: 'Κάντε κλικ εδώ για ανανέωση εάν πιστεύετε ότι πρέπει να έχετε παραγγελίες',
  deliveredBy: 'Παραδόθηκε από τον',
  by: 'Παραδόθηκε από τον',
  deliveryTime: 'Ωρα παράδοσης',
  pickupTime: 'Ωρα παραλαβής',
  customerPhone: 'Τηλέφωνο πελάτη',
  customerName: 'Ονομα πελάτη',
  orderNote: 'Σημείωση παραγγελίας',
  serviceCharge: 'Κόστος υπηρεσίας',
  orderType: 'Τύπος παράδοσης',
  deliveryCost: 'Κόστος παράδοσης',
  discountTotal: 'Εκπτωση',
  channelOrderDisplayId: 'id Παραγγελίας',
  orderIsAlreadyPaid: 'Πληρώθηκε',
  paymentMethod: 'Μέθοδος πληρωμής',
  deliveryFee: 'Κόστος παράδοσης',
  deliveryAddress: 'Διεύθυνση',
  deliveryPostcode: 'Ταχυδρομικός κωδικός',
  deliveryCity: 'Πόλη',
  extraAddressInfo: 'Επιπλέον πληροφορίες',
  postalCode: 'Ταχυδρομικός κωδικός',
  streetNumber: 'Αριθμός δρόμου',
  postponed: 'Αναβλήθηκε',
  warning_delay: 'Προειδοποίηση καθυστερημένες παραγγελίες (σε λεπτά)',
  load_orders_past: 'Φόρτωση παραγγελιών από τις προηγούμενες ημέρες',
  hotkeys: 'HotKeys',
  show_categories_separator: 'Εμφάνιση διαχωριστικού κατηγοριών σε κάθε στοιχείο παραγγελίας',
  auto_refresh: 'Αυτόματη ανανέωση λίστας παραγγελιών κάθε Χ λεπτά',
  auto_complete_order: 'Αυτόματη ολοκλήρωση παραγγελιών μετά από X λεπτά',
  hide_completed_items: 'Απόκρυψη ολοκληρωμένων στοιχείων από την παραγγελία',
  remove_grid_gaps: 'Αφαιρέστε τα κενά μεταξύ των παραγγελιών του πλέγματος',
  off: 'Κλειστό',
  languages: {"restart_msg": "Παρακαλώ, αποθηκεύστε τις ρυθμίσεις σας και ανανεώστε το πρόγραμμα περιήγησής σας για να αρχίσετε να χρησιμοποιείτε τη νέα μετάφραση.", "en": "English", "nl": "Nederlands", "de": "Deutsch", "pt": "Português", "es": "Español", "fr": "Français", "is": "Íslenska", "it": "Italiano", "no": "Norsk"},
  hotkeys_obj: {"error": "Δεν είναι δυνατή η επανάληψη των κλειδιών!", "select_order": "Επιλογή παραγγελιών από 1 έως 9", "change_status_info": "Αλλαγή κατάστασης επιλεγμένης παραγγελίας με:", "change_status": "Αλλαγή κατάστασης παραγγελίας"},
  last_update: 'Τελευταία ενημέρωση',
  customer_information: {"first_name": "Όνομα", "last_name": "Επώνυμο", "phone": "Τηλέφωνο"},
  in_progress: 'Σε εξέλιξη',
  deliveryBy: 'Παραδόθηκε από τον',
  init_all_services: 'Αρχικοποίηση όλων των υπηρεσιών ...',
  email: 'E-mail',
  signin: 'Συνδεθείτε',
  signin_error: 'Παρουσιάστηκε κάποιο πρόβλημα κατά τη σύνδεση',
  device_name: 'Ονομα της συσκευής',
  close_details: 'Κλείσιμο λεπτομερειών',
  no_orders_available: 'Δεν υπάρχουν διαθέσιμες παραγγελίες',
  order_has_been_completed: 'Η παραγγελία {{receipt_id}} ολοκληρώθηκε',
  no_products: 'Χωρίς Προϊόντα',
  print_device: 'Συσκευή εκτύπωσης',
  send_order: 'Αποστολή παραγγελίας',
  add_products: 'Προσθέστε προϊόντα',
  no_categories: 'Δεν βρέθηκαν κατηγορίες',
  empty_table: 'Άδειο τραπέζι',
  order_not_sent: 'Η παραγγελία δεν στάλθηκε στην κουζίνα',
  product_list_view: 'Προβολή λίστας προϊόντων',
  no_description: 'Αυτό το προϊόν δεν έχει περιγραφή. Μπορείτε να προσθέσετε ένα πατώντας το κουμπί "Αλλαγή" ή να επισκεφθείτε τον πίνακα ελέγχου σας.',
  change_description: 'Παρακαλώ εισάγετε την περιγραφή του προϊόντος.',
  main_cart_is_empty: 'Το κύριο καλάθι είναι άδειο',
  apply_to_all_carts: 'Εφαρμόστε σε όλα τα καροτσάκια',
  per_value_payment: '(Ανά τιμή πληρωμής)',
  select_a_seat: 'Επιλέξτε μια θέση',
  sub_total: 'Μερικό σύνολο',
  desktop_settings: 'Ρυθμίσεις desktop',
  carts: 'Καλάθια',
  signing_in: 'Σύνδεση ...',
  total_taxes: 'Συνολικοί φόροι',
  provide_refund_item: 'Πρέπει να επιλέξετε ένα στοιχείο για επιστροφή χρημάτων',
  manual_cheque_refund: 'Μη αυτόματη επιστροφή επιταγής',
  downloading_employees: 'Λήψη εργαζομένων ...',
  show_product_image_list: 'Εμφάνιση εικόνας προϊόντων στη λίστα προϊόντων',
  refresh_delta: 'Ανανέωση προϊόντων/κατηγοριών',
  admin_pin_incorrect: 'Λάθος PIN διαχειριστή! παρακαλώ προσπαθησε ξανα.',
  customer_not_valid_vat: 'Ο AΦM πελατών δεν είναι έγκυρος ή άδειος',
  allow_partial_payment: 'Επιτρέπεται η μερική πληρωμή',
  allow_qrcode_scanner: 'Επιτρέψτε στο σαρωτή κωδικών QR να εντοπίσει τη συναλλαγή',
  qrcode_not_found: 'Ο κωδικός QR δεν βρέθηκε',
  empty_pin: 'Το PIN πρέπει να έχει τουλάχιστον 1 χαρακτήρα',
  employee_select_error: 'Λάθος PIN υπαλλήλου',
  employee_select_success: 'Επιλεγμένος υπάλληλος {{employee}}.',
  employee_lock: 'Να κλειδώνετε πάντα τον εργαζόμενο μετά τη συναλλαγή',
  no_employee_store: 'Δεν υπάρχει εργαζόμενος που να έχει ανατεθεί σε αυτό το κατάστημα',
  cash_in_out_failed: 'Αποτυχία αποθήκευσης λειτουργίας Cash in/out',
  cash_in_out_success: 'Επιτυχία λειτουργίας Cash in/out',
  categories_alphabetic_order: 'Εμφάνιση κατηγοριών με αλφαβητική σειρά',
  products_alphabetic_order: 'Προβολή προϊόντων με αλφαβητική σειρά',
  weight_not_valid: 'Το βάρος δεν είναι έγκυρο',
  kiosk_mode_requirement: 'Δεν μπορείτε να ορίσετε τη λειτουργία Kiosk χωρίς PIN διαχειριστή',
  canceled: 'Ακυρώθηκε',
  partial_payment_value: 'Ανά τιμή',
  partial_payment_item: 'Ανά αντικείμενο',
  continue_payment: 'Συνεχίστε την πληρωμή',
  unit: 'Μονάδα',
  cheque: 'Cheque',
  need_store_register_device: 'Πρώτα πρέπει να δημιουργήσετε ένα κατάστημα για να συνδεθείτε. Επισκεφτείτε τον πίνακα ελέγχου για να δημιουργήσετε το πρώτο σας κατάστημα.',
  search_transaction_hint: 'Τουλάχιστον 3 χαρακτήρες και πατήστε ENTER',
  select_pay_method: 'Επιλέξτε τρόπο πληρωμής',
  delete_all_empty_cart: 'Διαγράψτε όλα τα άδεια καλάθια',
  delete_all_empty_table: 'Διαγράψτε όλα τα άδεια τραπέζια',
  delete_all_empty_cart_confirmation: 'Είστε βέβαιοι ότι θα διαγράψετε όλα τα άδεια καλάθια;',
  all_empty_carts_deleted: 'Τα καλάθια διαγράφηκαν ({{cartsNumber}} καλάθια)',
  reps: 'Απωλεσθέντα',
  show_reps_waste: 'Εμφάνιση του κουμπιού απώλειας και σπατάλης στο καλάθι αγορών',
  giveaway_reason: 'Λόγος προσφοράς ή απώλεια',
  countr_desktop_not_found: 'Countr Desktop δεν βρέθηκε',
  clear_search: 'Διαγραφή αναζήτησης',
  recovery_transaction_complete: 'Η συναλλαγή ({{ref}}) ολοκληρώθηκε.',
  activate_simple_tiles: 'Ενεργοποίηση απλής λίστας προϊόντων',
  not_print_receipt_cash: 'Μην εκτυπώνετε αυτόματη απόδειξη για μετρητά και δωροκάρτα',
  allow_negative_payments: 'Επιτρέψτε αρνητικό ποσό πώλησης',
  negative_payments_warning: 'Ενεργοποιήστε τις αρνητικές πληρωμές στις ρυθμίσεις (καρτέλα συναλλαγών) πριν το κάνετε.',
  enable_method: 'Ενεργοποίηση μεθόδου',
  error_addon_groups: 'Κάτι δεν πάει καλά με αυτή την Ομάδα Πρόσθετων αυτού του προϊόντος, ελέγξτε το Back Office.',
  billed_to: 'Χρεώθηκε σε',
  payment_due: 'Οφειλόμενη πληρωμή',
  amount_due: 'Οφειλόμενο ποσό',
  total_price: 'Συνολικό ποσό',
  show_invoice: 'Εμφάνιση τιμολογίου',
  hide_invoice: 'Απόκρυψη τιμολογίου',
  show_ean_sku_receipt: 'Εμφάνιση EAN στην απόδειξη',
  show_simple_receipt: 'Εμφάνιση απλής απόδειξης',
  not_tracked: 'Δεν παρακολουθείται',
  kitchen_print: 'Εκτύπωση κουζίνας',
  kitchen_delivery_print: 'Εκτύπωση παράδοσης κουζίνας',
  syncing_transactions: 'Συγχρονισμός συναλλαγών ...',
  syncing_refunds: 'Συγχρονισμός επιστροφών χρημάτων ...',
  barcode_customer: 'Χρησιμοποιήστε barcode scanner για αναζήτηση πελάτη',
  customer_add_cart: 'Ο πελάτης προστέθηκε στο καλάθι',
  transaction_not_sync_yet: 'Η συναλλαγή δεν ήταν ακόμη συγχρονισμένη με τον server.',
  intercom_error: 'Δεν είναι δυνατή η εκκίνηση του πρόσθετου Intercom, ρίξτε μια ματιά εάν έχετε κάποια επέκταση αποκλεισμού διαφημίσεων ή προσπαθήστε να το χρησιμοποιήσετε στο Dashboard.',
  pay_before_open_error: 'Πρέπει να ανοίξετε τη βάρδια σας πριν κάνετε συναλλαγές',
  cash_in_not_open_day: 'Πρέπει να ανοίξετε τη βάρδια σας προτού πραγματοποιήσετε ενέργειες Cash in/out',
  cash_in_greater_start: 'Μη έγκυρο ποσό, εάν διαθέτετε περισσότερα μετρητά από όσα δηλώθηκαν στην έναρξη βάρδιας, κάντε πρώτα Cash In.',
  open_close_shift: 'Άνοιγμα & Κλείσιμο βάρδιας',
  opening_shift: 'Άνοιγμα βάρδιας ...',
  closing_shift: 'Κλείσιμο βάρδιας ...',
  not_valid_input: 'Το ποσό δεν είναι έγκυρο',
  previous_start_amount: 'Αναμενόμενο ποσό',
  reason_mandatory_helper: '* Υπάρχει διαφορά, συμπληρώστε τον λόγο παρακαλώ.',
  printer_settings: 'Ρυθμίσεις εκτυπωτή',
  printer_ip: 'IP εκτυπωτή',
  test_printers: 'Δοκιμαστικοί εκτυπωτές',
  printers_settings_saved: 'Οι ρυθμίσεις εκτυπωτών αποθηκεύτηκαν',
  error_shift_start_day: 'Η ημέρα έναρξης βάρδιας δεν βρέθηκε',
  no_connection_payment: 'Δεν υπάρχει σύνδεση στο διαδίκτυο, βεβαιωθείτε ότι έχετε επαναφέρει τη σύνδεση πριν από την πληρωμή με καρφίτσα.',
  show_order_bill: 'Εμφάνιση κουμπιών παραγγελίας/λογαριασμού στην κεφαλίδα των καροτσιών',
  split_orders_items: 'Διαχωρίστε τις παραγγελίες εκτύπωσης ανά τεμάχιο',
  split_orders_amount: 'Διαχωρίστε τις παραγγελίες εκτύπωσης ανά ποσό',
  partial_payment_print: 'Εκτύπωση απόδειξης για κάθε μερική πληρωμή',
  minimum_age: 'Επαλήθευση ελάχιστης ηλικίας',
  minimum_age_alert: 'Πελάτης {{customer}}. Βεβαιωθείτε ότι ο πελάτης είναι άνω των {{age}} ετών',
  customer_required_title: 'Απαιτείται πελάτης',
  customer_required_text: 'Απαιτείται πελάτης για αυτόν τον τρόπο πληρωμής',
  show_pretax_receipt: 'Εμφάνιση του ποσού των φόρων',
  show_full_receipt_after_payment: 'Εμφάνιση πλήρους απόδειξης μετά την πληρωμή',
  new_version_title: 'New version available',
  new_version_text: 'Διατίθεται νέο περιεχόμενο. Ανανεώστε το.',
  new_version_text_hint: 'Μπορείτε να ελέγξετε τις σημειώσεις έκδοσης στο αριστερό μενού.',
  reload: 'Φορτώνω πάλι',
  release_note: 'Σημείωση έκδοσης',
  play_sound_success_scan: 'Αναπαραγωγή ήχου κατά το επιτυχές σάρωμα barcode',
  manual_sync_transactions: 'Μη αυτόματος συγχρονισμός συναλλαγής',
  manual_sync_transactions_question: 'Θέλετε να συγχρονίσετε αυτές τις συναλλαγές με μη αυτόματο τρόπο;',
  improvement: 'ΒΕΛΤΙΩΣΗ',
  fixed: 'ΔΙΟΡΘΩΣΗ',
  recover_carts: 'Ανάκτηση καλαθιών',
  recover_carts_alert: 'Είστε βέβαιοι ότι θέλετε να μεταφέρετε το καλάθι σε αυτήν τη συσκευή; Μόλις μεταφέρετε το καλάθι, θα αφαιρεθεί από την άλλη συσκευή.',
  recover_carts_complete: 'Ολοκληρώστε την ανάκτηση των καλαθιών. Τώρα μπορείτε να το επιλέξετε στο αναδυόμενο παράθυρο επιλογής καλαθιού.',
  select_all_carts: 'Επιλέξτε όλα τα καλάθια',
  payment_started: 'Η πληρωμή ξεκίνησε',
  outstanding_inovices: 'Έχετε εκκρεμή τιμολόγια - επισκεφτείτε το Dashboard για να τα συνδυάσετε',
  quota_warning_title: 'Το όριο αποθήκευσης φτάνει τη μέγιστη χωρητικότητα',
  quota_warning_text: 'Χρησιμοποιείτε το {{percent}}% της χωρητικότητας.',
  quota_warning_text_hint: 'Προσπαθήστε να ελευθερώσετε τον χώρο του υπολογιστή σας.',
  welcome_to: 'Καλωσόρισες στο',
  kassa_open: 'Διαθέσιμη ταμειακή μηχανή',
  kassa_closed: 'Κλειστή ταμειακή μηχανή',
  kassa_closed_hint: 'Μεταβείτε σε άλλο μητρώο',
  enable_customer_screen: 'Ενεργοποίηση οθόνης πελάτη',
  cds_settings: 'Ρυθμίσεις οθόνης πελάτη',
  cds_port: 'Θύρα εμφάνισης πελάτη',
  web_pos: 'Web POS',
  desktop_pos: 'Desktop POS',
  pos: 'App POS',
  no_internet_connection: 'Δεν μπορείτε να εκτελέσετε αυτήν την ενέργεια. Δεν υπάρχει σύνδεση στο διαδίκτυο.',
  storage: 'Αποθήκευση',
  storage_data: 'Αποθηκευτικός χώρος που χρησιμοποιήθηκε: {{quota}}%',
  extra_info_title: 'Εισαγάγετε επιπλέον στοιχεία στοιχείων',
  extra_info_sub_title: 'Δωροκάρτα ή αριθμός αναφοράς',
  net: 'Καθαρά',
  delivery_details: 'Λεπτομέρειες παράδοσης',
  delivery_time: 'Ωρα παράδοσης',
  pickup_time: 'Ωρα παραλαβής',
  order_type: 'Τύπος παραγγελίας',
  order_courier: 'Παραδόθηκε από τον',
  delivery_order_warning: 'Αυτή είναι μια παραγγελία παράδοσης από την {{source}}, δεν μπορεί να προστεθεί νέο προϊόν σε αυτήν.',
  delivery_toast: 'Η παραγγελία παράδοσης ελήφθη: {{by}} - {{receipt_id}}',
  delivery_requested: 'Ζητήθηκε παράδοση: {{message}}',
  tables_or_carts_desc: 'Εμφάνιση των ονομάτων των καλαθιών ως πίνακες (π.χ. το Καλάθι 1 γίνεται Tραπέζι 1).',
  cart_always_open_desc: 'Κρατήστε το καλάθι (είδη, σύνολο, φόροι, ...) ανοιχτό στη δεξιά πλευρά της οθόνης.',
  show_product_image_desc: 'Εμφάνιση εικόνας προϊόντος μέσα στη λίστα στοιχείων καλαθιού.',
  allow_cart_discounts_desc: 'Επιτρέψτε την έκπτωση στο συνολικό καλάθι.',
  allow_product_discounts_desc: 'Επιτρέψτε την έκπτωση σε ένα προϊόν.',
  enable_cart_item_remove_desc: 'Κουμπί ενεργοποίησης για να αφαιρέσετε το στοιχείο από το καλάθι στη λίστα στοιχείων του καλαθιού.',
  show_calculator_desc: 'Εμφάνιση αριθμητικού πληκτρολογίου για να επιλέξετε την ποσότητα των προϊόντων.',
  show_reps_waste_desc: 'Εμφάνιση κουμπιού απωλεσθέντωνστην κεφαλίδα του καλαθιού.',
  barcode_customer_desc: 'Επιτρέψτε στο barcode scanner να σαρώσει το διακριτικό πελάτη και να προσθέσει τον πελάτη στο καλάθι.',
  show_stock_level_desc: 'Εμφάνιση αποθέματος του προϊόντος στο πλακίδιο του προϊόντος.',
  show_price_desc: 'Εμφάνιση της τιμής του προϊόντος στο πλακίδιο του προϊόντος.',
  barcode_scanner_desc: 'Ενεργοποιήστε το barcode scanner για να σαρώσετε ένα προϊόν και να το προσθέσετε στο καλάθι.',
  play_sound_success_scan_desc: 'Αναπαραγωγή ήχου κατά τη σάρωση ενός προϊόντος με επιτυχία και προσθήκη του στο καλάθι.',
  show_product_image_list_desc: 'Εμφάνιση εικόνας προϊόντος στη λίστα προϊόντων.',
  activate_simple_tiles_desc: 'Αφαιρέστε κινούμενα σχέδια και εφέ πλακιδίων προϊόντων (συνιστάται για παλιούς υπολογιστές).',
  product_tile_size_desc: 'Διπλό μέγεθος πλακιδίου προϊόντος',
  hide_categories_desc: 'Απόκρυψη λίστας cartegories και προσθήκη modal για να την επιλέξετε.',
  categories_alphabetic_order_desc: 'Εμφάνιση λίστας κατηγοριών με αλφαβητική σειρά.',
  products_alphabetic_order_desc: 'Εμφάνιση λίστας προϊόντων με αλφαβητική σειρά.',
  skip_payment_success_info_desc: 'Να μην εμφανίζεται το παράθυρο διαλόγου επιτυχίας πληρωμής (συνέχιση συναλλαγής) μετά από επιτυχή πληρωμή.',
  allow_partial_payment_desc: 'Να επιτρέπεται η μερική πληρωμή (ο πελάτης μπορεί να πληρώσει το σύνολο με διαφορετικούς τρόπους πληρωμής).',
  allow_negative_payments_desc: 'Επιτρέψτε την πληρωμή με αρνητική αξία.',
  allow_qrcode_scanner_desc: 'Εμφάνιση του προγράμματος ανάγνωσης qrcode στην κεφαλίδα του καλαθιού.',
  show_pretax_receipt_desc: 'Εμφάνιση αμοιβής προ φόρων στην απόδειξη.',
  show_full_receipt_after_payment_desc: 'Εμφάνιση πλήρους απόδειξης στο βιογραφικό μετάβασης μετά την επιτυχή πληρωμή.',
  refund_same_method_desc: 'Ενεργοποιήστε την επιστροφή χρημάτων με την ίδια μέθοδο που έγινε στις συναλλαγές.',
  enable_customer_screen_desc: 'Ενεργοποιήστε τα στοιχεία της οθόνης πελάτη (πρέπει να διαθέτετε υλικό εμφάνισης πελάτη).',
  kiosk_mode_desc: 'Ενεργοποιήστε τη λειτουργία περιπτέρου.',
  admin_pin_desc: 'Ενεργοποίηση PIN διαχειριστή (για κλείδωμα/ξεκλείδωμα λειτουργίας περιπτέρου).',
  employee_pin_desc: 'Ενεργοποίηση επιλογής υπαλλήλων.',
  employee_lock_desc: 'Εάν το PIN του εργαζομένου είναι ενεργοποιημένο, μπορείτε να κλειδώσετε το POS μετά από κάθε συναλλαγή και πρέπει να επιλέξετε έναν νέο υπάλληλο για να συνεχίσετε να το χρησιμοποιείτε.',
  open_close_shift_desc: 'Ενεργοποίηση λειτουργίας ανοίγματος/κλεισίματος αλλαγής.',
  reports_at_store_level_desc: 'Εμφάνιση αναφορών σε επίπεδο location (μετρώντας όλες τις συναλλαγές όλων των συσκευών).',
  language_desc: 'Αλλαγή γλώσσας POS.',
  kitchen_print_desc: 'Εκτύπωση παραγγελίας μετά την επιτυχία της συναλλαγής.',
  kitchen_delivery_print_desc: 'Εκτυπώστε την παραγγελία αφού λάβετε μια συναλλαγή με επιτυχία μέσω συστήματος παράδοσης.',
  show_order_bill_desc: 'Εμφάνιση σειράς εκτύπωσης και λογαριασμού στην κεφαλίδα του καλαθιού.',
  split_orders_items_desc: 'Χωρίστε την παραγγελία εκτύπωσης σε στοιχεία.',
  split_orders_amount_desc: 'Χωρίστε την παραγγελία εκτύπωσης σε ποσό.',
  partial_payment_print_desc: 'Εκτυπώστε μερική απόδειξη μετά από κάθε μερική πληρωμή.',
  show_ean_sku_receipt_desc: 'Εμφάνιση EAN/SKU στην απόδειξη.',
  a4_paper_desc: 'Ενεργοποιήστε την εκτύπωση A4.',
  show_carts_list_alphabetic: 'Εμφάνιση λίστας καλαθιών με αλφαβητική σειρά',
  show_carts_list_alphabetic_desc: 'Εμφάνιση λίστας καλαθιών με αλφαβητική σειρά',
  show_cart_name_transaction: 'Εμφάνιση ονόματος καλαθιού στην απόδειξη',
  show_cart_name_transaction_desc: 'Εμφάνιση του ονόματος του καλαθιού στην απόδειξη (η λίστα συναλλαγών ή η συναλλαγή ολοκληρώθηκε).',
  open_kds: 'KDS',
  open_chat: 'Countr Chat',
  partial_payment_bigger_zero: 'Η μερική αξία πληρωμής πρέπει να είναι μεγαλύτερη από 0',
  allow_product_exchanges: 'Επιτρέψτε την ανταλλαγή προϊόντων',
  allow_product_exchanges_desc: 'Χαρακτηριστικό προσθήκη προϊόντων που ο πελάτης θέλει να ανταλλάξει και προσθέστε το ως αρνητικό ποσό στο καλάθι',
  exchangeable_items: 'Ανταλλάξιμα αντικείμενα',
  to_be_exchangeable: 'Να είναι ανταλλάξιμο',
  exchange_source: 'Πηγή ανταλλαγής',
  inventory_not_found: 'Το απόθεμα του προϊόντος δεν βρέθηκε',
  drop_ship: 'Drop-Ship',
  enable_drop_ship: 'Ενεργοποίηση drop-shipping',
  enable_drop_ship_desc: 'Ενεργοποιήστε τη δυνατότητα drop-shipping.',
  drop_ship_warning: 'Drop -Ship - Δεν έχει επιλεγεί πελάτης',
  cart_as_tables_not_available: 'Η λειτουργία καλαθιών ως τραπέζια δεν επηρεάζει την επέκταση διάταξης τραπεζιών.',
  enable_deposit_button: 'Ενεργοποίηση κουμπιού κατάθεσης',
  enable_deposit_button_desc: 'Ενεργοποίηση κουμπιού κατάθεσης στην υποκεφαλίδα του καλαθιού',
  allow_voucher_scan_desc: 'Χρησιμοποιήστε barcode scanner για να αναζητήσετε κουπόνι έκπτωσης και να το εφαρμόσετε.',
  remove_customer: 'Αφαίρεση πελάτη',
  remove_customer_question: 'Θέλετε να αφαιρέσετε τον πελάτη από αυτό το καλάθι;',
  search_product_barcode_server: 'Αναζήτηση προϊόντων στο server',
  search_product_barcode_server_desc: 'Αναζητήστε το προϊόν στο server εάν δεν βρίσκεται τοπικά.',
  show_floorplan_after_sale_desc: 'Εμφάνιση διάταξης πατώματος μετά από κάθε πώληση αντί εμφάνισης λίστας προϊόντων',
  ccv_settings: 'Ρυθμίσεις CCV',
  ask_no_covers_or_takeaway_desc: 'Να ρωτάτε πάντα τη διαμόρφωση των τραπεζιών',
  send_order_kds: 'Αποστολή παραγγελίας στο KDS',
  mollie: 'Mollie',
  paynl: 'PayNL',
  transactionFee: 'Τέλος συναλλαγής',
  pay_later_note_partial: '* Είναι δυνατή μόνο 1 μεταγενέστερη πληρωμή ανά συναλλαγή.',
  no_print_delivery: 'Δεν υπάρχουν πληροφορίες παράδοσης για αυτήν την παραγγελία.',
  load_linked_cart: 'Κάντε κλικ στον πίνακα για να φορτώσετε τις συνδεδεμένες πληροφορίες.',
  show_item_status: 'Εμφάνιση κατάστασης στοιχείου στην καταχώρηση του καλαθιού',
  show_item_status_desc: 'Εμφάνιση περιγράμματος επισήμανσης στα αριστερά της καταχώρισης καλαθιού με την τρέχουσα κατάσταση αυτού του στοιχείου.',
  access_denied: 'Δεν επιτρέπεται η πρόσβαση! Προσπαθήστε να συνδεθείτε με τον κύριο λογαριασμό.',
  current_floor: 'Τρέχον πάτωμα',
  total_to_pay: 'Σύνολο προς πληρωμή: {{currency}} {{amount}}',
  total_amount_paid: 'Σύνολο πληρωμένων: {{currency}} {{amount}}',
  total_remaining: 'Συνολικό υπόλοιπο: {{currency}} {{amount}}',
  redeem: 'Εξαργυρώνω',
  current_balance: 'Υπόλοιπο',
  card_activity: 'Δραστηριότητα καρτών',
  scan_fail: 'Η αναζήτηση σάρωσης κώδικα απέτυχε',
  disallow_offline: 'Το POS δεν μπορεί να λειτουργήσει εκτός σύνδεσης',
  disallow_offline_fiscal: 'Το POS δεν μπορεί να λειτουργήσει εκτός σύνδεσης λόγω δημοσιονομικού νόμου.',
  fiscal_print_error: 'Δεν είναι δυνατή η εκτύπωση απόδειξης χωρίς δημοσιονομικές πληροφορίες',
  enable_giftcard_scan: 'Ενεργοποιήστε τη σάρωση δυνατότητας',
  enable_giftcard_scan_desc: 'Ενεργοποιήστε τη δυνατότητα σάρωσης της δωροκάρτας και μειώστε το υπόλοιπό της.',
  print_qr: 'Εκτυπώστε τον κωδικό QR στην απόδειξη',
  print_qr_desc: 'Ενεργοποιήστε την εκτύπωση κωδικού QR στο κάτω μέρος της απόδειξης.',
  theme: 'Θέμα',
  theme_desc: 'Αλλάξτε το προεπιλεγμένο θέμα σε ένα που σας αρέσει (η λίστα θεμάτων παρέχεται ανά κατάστημα).',
  invalid_addon_groups: 'Κάτι δεν πάει καλά με τις ομάδες πρόσθετων προϊόντων. Μεταβείτε στον πίνακα ελέγχου και ελέγξτε τον πριν τον χρησιμοποιήσετε.',
  products_not_found: 'Τα προϊόντα δεν βρέθηκαν',
  show_suggestion_amounts: 'Εμφάνιση κουμπιών ποσού πρότασης',
  show_suggestion_amounts_desc: 'Εμφάνιση/απόκρυψη κουμπιών ποσού πρότασης στον τρόπο πληρωμής.',
  save_table: 'Αποθήκευση',
  save_cart: 'Αποθήκευση',
  enable_groups: 'Ομαδικά στοιχεία παραγγελίας',
  highlight_items: 'Επισημάνετε στοιχεία',
  set_ready_print: 'Ορισμός ως Έτοιμο και Εκτύπωση',
  start_preparing: 'Ξεκινήστε την προετοιμασία',
  group_qty: 'Ομαδική ποσότητα',
  show_more: 'Εμφάνιση περισσότερων πληροφοριών',
  notes_per_qty: 'Σημειώσεις ανά ποσότητα',
  print_online_order_receipts: 'Αυτόματη εκτύπωση αποδείξεων για πληρωμένες online παραγγελίες',
  select_items: 'Επιλέξτε προϊόντα',
  partial_payment_not_allowed_by_value: 'Δεν επιτρέπονται μερικές πληρωμές',
  product_media: 'Media',
  show_product_media: 'Εμφάνιση media προϊόντος',
  change_seat: 'Αλλαγή καθίσματος',
  add_seat: 'Προσθέστε κάθισμα',
  continue_partial_payment: 'Κάνετε άλλη μερική πληρωμή;',
  seat: 'Θέση',
  partial_payment_seat: 'Ανά θέση',
  processing: 'Επεξεργασία',
  groupOrderPrintBySeat: 'Ομαδική εκτύπωση παραγγελιών ανά θέση',
  fiscal_error: 'Σφάλμα δημοσιονομικής μονάδας',
  switch_employee: 'Αλλαγή υπαλλήλου',
  no_order_removed: 'Τα μη παραγγελθέντα προϊόντα αφαιρέθηκαν',
  connected_device: 'Συνδεδεμένη συσκευή',
  deleted: 'Διαγράφηκε',
  ready_pickup: 'έτοιμο για παραλαβή',
  employee_pin_changed: 'Ο κωδικός PIN του εργαζομένου άλλαξε με επιτυχία',
  order_kds_no_printer_msg: 'Η παραγγελία στάλθηκε στο KDS αλλά όχι στον εκτυπωτή, ρυθμίστε τη συσκευή εκτυπωτή για αποστολή της παραγγελίας',
  order_kds_printer_msg: 'Η παραγγελία στάλθηκε στο KDS και στον εκτυπωτή',
  add_table: 'προστέθηκε στο τραπέζι',
  product_add_cart: 'Το προϊόν προστέθηκε στο καλάθι',
  product_ordered: 'Το προϊόν παραγγέλθηκε',
  product_not_ordered: 'Το προϊόν δεν παραγγέλθηκε',
  save_guests: 'Αποθήκευση καλεσμένων',
  guests_num: 'Αριθμός των καλεσμένων',
  add_guest_num: 'Προσθέστε αριθμό επισκεπτών',
  no_stores_created: 'Δεν δημιουργήθηκαν καταστήματα',
  device_print_msg: 'Η επιλεγμένη συσκευή θα είναι υπεύθυνη για την εκτύπωση της απόδειξης',
  prep_time: 'Ωρα προετοιμασίας',
  no_tables_store: 'Δεν υπάρχουν τραπέζια για αυτό το κατάστημα',
  go_back: 'Πήγαινε πίσω!',
  remove_not_order_msg: 'Είστε βέβαιοι ότι θέλετε να καταργήσετε τα στοιχεία "Δεν έχουν παραγγελθεί" των',
  order_not_sent_back: 'Η παραγγελία δεν έχει σταλεί στην KDS! Είστε σίγουροι ότι θέλετε να επιστρέψετε;',
  no_available_addons_for_products: 'Δεν υπάρχουν διαθέσιμα πρόσθετα για το επιλεγμένο προϊόν.',
  no_logged_employee: 'Κανένας υπάλληλος δεν είναι συνδεδεμένος!',
  empty_tables: 'Άδειοι πίνακες',
  close_search: 'Κλείσιμο γραμμής αναζήτησης',
  no_empty_tables: 'Δεν υπάρχουν άδεια τραπέζια!',
  my_tables: 'Οι Πίνακες μου',
  current_device: 'Τρέχουσα συσκευή',
  employee_info: 'Πληροφορίες Εργαζομένου',
  show_cart: 'Εμφάνιση Καλαθιού',
  customer_groups: 'Ομάδες πελατών',
  sales_insights: 'Πληροφορίες για τις πωλήσεις',
  product_insights: 'Πληροφορίες προϊόντος',
  store_insights: 'Πληροφορίες καταστήματος',
  allow_ticket_scan: 'Ελέγξτε για κουπόνια χρησιμοποιώντας barcode scanner',
  no_pin_employee: 'Υπάλληλος χωρίς PIN',
  allow_ticket_scan_desc: 'Χρησιμοποιήστε το σαρωτή γραμμωτού κώδικα για να αναζητήσετε εισιτήρια.',
  admin_only_custom_item: 'Ενεργοποίηση προσαρμοσμένου στοιχείου μόνο για διαχειριστή',
  admin_only_custom_item_desc: 'Μόνο οι χρήστες με δικαιώματα διαχειριστή μπορούν να δημιουργήσουν ένα προσαρμοσμένο στοιχείο. Πρέπει επίσης να ενεργοποιήσετε τo PIN υπαλλήλου.',
  mandatory_employee_pin: 'Υποχρεωτική ενεργοποίηση PIN υπαλλήλου.',
  admin_only_refunds: 'Μόνο οι διαχειριστές μπορούν να πραγματοποιήσουν επιστροφές χρημάτων',
  admin_only_refunds_desc: 'Μόνο οι διαχειριστές μπορούν να πραγματοποιούν επιστροφές χρημάτων. Πρέπει επίσης να ενεργοποιήσετε το PIN υπαλλήλου.',
  round_cash_transactions_desc: 'Στρογγυλοποιεί αυτόματα τις πληρωμές με μετρητά στο πλησιέστερο 0,05',
  unlimited_usage: 'Απεριόριστη χρήση',
  infinite: 'Απεριόριστες',
  disable_custom_discounts: 'Απενεργοποιήστε τις προσαρμοσμένες εκπτώσεις',
  disable_custom_discounts_desc: 'Απενεργοποιήστε τις προσαρμοσμένες εκπτώσεις, αλλά επιτρέψτε τις προκαθορισμένες.',
  guest: 'Επισκέπτες',
  select_method: 'Επιλέξτε μέθοδο',
  manual_insert: 'Χειροκίνητα',
  enter_code: 'Εισάγετε τον κωδικό',
  existing_by_value_payment: '* Δεν μπορείτε να συνδυάσετε πληρωμή ανά αξία και πληρωμή ανά θέση ή/και αντικείμενο',
  order_sent: 'Η Παραγγελία Eστάλη',
  select_multiple_seats: 'Επιλέξτε πολλαπλές θέσεις',
  change_seat_or_guest_num: 'Αλλαγή θέσης ή αριθμού επισκεπτών',
  change_guest_num: 'Αλλαγή αριθμού επισκέπτη',
  giftcard_low_value: '* Το υπόλοιπο της δωροκάρτας είναι χαμηλότερο από την τιμή των επιλεγμένων αντικειμένων. Δοκιμάστε να πληρώσετε με βάση την αξία.',
  received_amount: 'Ληφθέν ποσό',
  order_products_action: 'Παραγγελία προϊόντων',
  ordered_products: 'Παραγγελθέντα προϊόντα',
  select_table: 'Επιλέξτε τραπέζι',
  ordered: 'Παραγγελθέντα',
  scan_or_type: 'Σάρωση ή πληκτρολόγηση',
  type_code: 'Πληκτρολογήστε τον κωδικό',
  number_extension: 'Επέκταση αριθμού',
  move_processing_ticket_first: 'Μετακινήστε πρώτα τα εισιτήρια διεκπεραίωσης',
  disable_adblocker: 'Απενεργοποιήστε το AdBlocker και ανανεώστε τη σελίδα',
  remove_partial_payment_by_item: 'Εντοπίστηκε μερική πληρωμή ανά είδος! Αφαιρέστε τη για να προχωρήσετε σε αυτήν την ενέργεια!',
  add_nutrients: 'Προσθέστε θρεπτικά συστατικά',
  giftcard_warning_msg: 'Η τυπική ισχύς μιας δωροκάρτας είναι 2 χρόνια. Είστε βέβαιοι ότι θέλετε να το μειώσετε;',
  edit_devicess_main_details: 'Επεξεργαστείτε τα κύρια στοιχεία των συσκευών.',
  create_custom_payment_methods_for_your_device: 'Δημιουργήστε προσαρμοσμένους τρόπους πληρωμής για τη συσκευή σας.',
  show_open_carts_from_device: 'Εμφάνιση ανοιχτών παραγγελιών από τη συσκευή',
  create_discounts_for_your_stores: 'Δημιουργήστε εκπτώσεις για την τοποθεσία σας.',
  create_reps_waste_for_your_stores: 'Δημιουργήστε επαναλήψεις και σπατάλη για την τοποθεσία σας.',
  show_cat_bar: 'Εμφάνιση γραμμής κατηγορίας',
  bulk_update: 'Μαζική ενημέρωση στοιχείων',
  show_category_product_info: 'Εμφάνιση πληροφοριών κατηγορίας στο προϊόν',
  running_payment: 'Σε εξέλιξη',
  ready_items: 'Έτοιμα προϊόντα',
  disabled: 'Απενεργοποιημένες',
  ready_orders: 'Έτοιμες παραγγελίες',
  changes_list: 'Λίστα αλλαγών',
  action_type: 'Τύπος',
  previous_change: 'Προηγούμενη αλλαγή',
  new_changes: 'Νέες αλλαγές',
  removed_fields: 'Καταργήθηκαν πεδία',
  updated_fields: 'Ενημερωμένα πεδία',
  full_history: 'Πλήρες ιστορικό',
  changes_history: 'Αλλαγές',
  product_history: 'Ιστορικό προϊόντος',
  category_history: 'Ιστορικό κατηγορίας',
  device_history: 'Ιστορικό συσκευής',
  enable_mandatory_customer_address: 'Υποχρεωτική διεύθυνση πελάτη',
  please_fill_all_fields: 'Συμπληρώστε όλα τα απαιτούμενα πεδία: αριθμός κτιρίου, οδός, πόλη, πολιτεία/επαρχία, ταχυδρομικός κώδικας, χώρα, όνομα και επώνυμο, διεύθυνση email ή αριθμός τηλεφώνου',
  select_file: 'Επιλέξτε αρχείο',
  drop_file: 'Απόθεση αρχείου',
  create_media: 'Δημιουργία πολυμέσων για',
  upload_image: 'Μεταφόρτωση εικόνας',
  upload_file: 'Ανέβασμα αρχείου',
  add_custom_img: 'Προσθέστε την προσαρμοσμένη εικόνα σας',
  select_media: 'Επιλέξτε τύπο μέσου',
  external_url_iframe: 'Εξωτερική διεύθυνση URL Iframe',
  select_ticket_print: 'Επιλέξτε τύπο για εκτύπωση',
  external_service_provider: 'Εξωτερικός πάροχος υπηρεσιών',
  barcode_input: 'Εισαγωγή γραμμικού κώδικα',
  stock_load_msg: 'Έλεγχος όλων των προϊόντων για απόθεμα. Αυτό μπορεί να πάρει λίγο χρόνο. Παρακαλώ περιμένετε!',
  no_available_stores: 'Δεν υπάρχουν διαθέσιμα καταστήματα.',
  buy_combination_get_deal_new_price: 'Συνδυάστε αντικείμενα για μια τιμή συμβολαίου',
  discount_specific_products_categories: 'Έκπτωση σε συγκεκριμένα προϊόντα ή κατηγορίες',
  discount_all_prodcucts: 'Έκπτωση σε όλα τα προϊόντα',
  buy_x_items_get_cheapest_discount: 'Αγοράστε x αντικείμενα, αποκτήστε το φθηνότερο με έκπτωση',
  spend_certain_amount_get_discount: 'Ξοδέψτε ένα συγκεκριμένο ποσό, κερδίστε έκπτωση',
  customer_group_item_discount: 'Έκπτωση σε συγκεκριμένες ομάδες πελατών',
  item_surcharge: 'Επιβάρυνση για συγκεκριμένα προϊόντα, κατηγορίες ή ομάδες πελατών',
  cart_surcharge: 'Επιβάρυνση για όλη την παραγγελία',
  import: 'εισαγωγή',
  export: 'Εξαγωγή',
  simple: 'Απλός',
  advanced: 'Προχωρημένος',
  delete_import: 'Διαγραφή εισαγωγής προϊόντων',
  upload_csv: 'Ανεβάστε το αρχείο CSV σας',
  open_file: 'Ανοίξτε το αρχείο χρησιμοποιώντας',
  save_file: 'Αποθηκεύστε το αρχείο ως .csv',
  value_remaining: 'Αξία που απομένει:',
  shared_shift: 'Μετατόπιση κοινής χρήσης',
  shared_shift_desc: 'Ο εργαζόμενος που αλλάζει δεν θα χρειαστεί να ανοίξει ξανά το μητρώο',
  sample_check_msg_top: 'Παρακαλώ περιμένετε. Θα πρέπει να γίνει έλεγχος.',
  sample_check_msg_bottom: 'Θα έρθει υπάλληλος το συντομότερο δυνατό.',
  full_basket_check: 'Έλεγχος πλήρους καλαθιού',
  missing_items_amount: 'Ποσό στοιχείων που λείπουν',
  extra_items_scanned: 'Ποσό επιπλέον στοιχείων',
  finish_check: 'Ολοκλήρωση ελέγχου',
  original_items: 'Πρωτότυπα είδη',
  checked_items: 'Ελεγμένα στοιχεία',
  merging: 'Συγχώνευση σε εξέλιξη',
  select_table_from_list: 'Επιλέξτε έναν πίνακα από τη λίστα για να συγχωνεύσετε όλους τους άλλους επιλεγμένους πίνακες',
  cant_merge_same: 'Δεν είναι δυνατή η συγχώνευση του ίδιου πίνακα, επιλέξτε έναν άλλο πίνακα για συγχώνευση',
  remove_from_table: 'αφαιρέστε από το τραπέζι',
  eat_in: 'Φάτε μέσα',
  preparing_ods: 'Προετοιμασία',
  ready_ods: 'Ετοιμος',
  locked_pos: 'Το POS είναι κλειδωμένο',
  fiscal_store_employee: 'Τα φορολογικά καταστήματα πρέπει να έχουν υπάλληλο μητρώου για να λειτουργούν POS',
  invalid_employee: 'Άκυρος υπάλληλος',
  update_employee_ssn: 'Ενημερώστε τον αριθμό κοινωνικής ασφάλισης υπαλλήλου',
  social_security_number: 'Αριθμός κοινωνικής ασφάλισης',
  fiscal_one_employee: 'Πρέπει να έχετε τουλάχιστον έναν υπάλληλο εγγεγραμμένο σε ένα κατάστημα φορολογίας',
  fiscal_employee_ssn: 'Οι εργαζόμενοι που εγγράφονται στα φορολογικά καταστήματα πρέπει να έχουν έγκυρο αριθμό κοινωνικής ασφάλισης',
  employee_unable_delete: 'Δεν είναι δυνατή η διαγραφή του υπαλλήλου επειδή είναι συνδεδεμένος σε ένα κατάστημα φορολογίας. Παρακαλούμε αφαιρέστε πρώτα τον υπάλληλο από το κατάστημα.',
  vat_receipt: 'Πρόκειται για απόδειξη ΦΠΑ',
  not_vat_receipt: 'Δεν πρόκειται για απόδειξη ΦΠΑ',
  deposit_error: 'Σφάλμα κατάθεσης',
  deposit_error_message: 'Δεν μπορείτε να έχετε αρνητική κατάθεση συνδεδεμένη με ένα προϊόν με θετική τιμή. Μόνο προϊόντα με τιμή 0 ή μικρότερη μπορούν να έχουν αρνητική κατάθεση.',
  assign_printers_to_your_location: 'Αντιστοιχίστε εκτυπωτές στην τοποθεσία σας',
  save_new_printer: 'Αποθήκευση νέου εκτυπωτή',
  update_printer: 'Ενημέρωση εκτυπωτή',
  location_no_saved_printers: 'Δεν υπάρχουν αποθηκευμένοι εκτυπωτές για αυτήν την τοποθεσία',
  sort_items_by_action: 'Ταξινόμηση αντικειμένων ανά δράση (προετοιμασία, νέα, έτοιμα)',
  print_x_report: 'Εκτύπωση αναφοράς Χ',
  shox_x_report: 'Εμφάνιση αναφοράς X',
  print_z_report: 'Εκτύπωση αναφοράς Z',
  show_z_report: 'Εμφάνιση αναφοράς Z',
  unfiscalised_transactions_report_incorrect: '{{transaction_number}} συναλλαγές που δεν συγχρονίστηκαν - οι αναφορές δεν είναι ενημερωμένες',
  refund_print: 'Επιστροφή χρημάτων',
  download_paynl_app: 'Κατεβάστε την εφαρμογή Pay.',
  paynlsoftpos: 'Pay. SoftPOS'
}

export default elLang
